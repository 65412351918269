import { CountryCode } from "pages/employer-of-record/onboarding/constants";
import { EmployeePayType } from "pages/employer-of-record/payments/constants";
import { BONUS_INITIAL_VALUES as CA_BONUS_INITIAL_VALUES } from "./BonusPay/countryConfigs/CA/initialValues";
import { COMISSION_INITIAL_VALUES as CA_COMISSION_INITIAL_VALUES } from "./Comission/countryConfigs/CA/initialValues";
import { OFF_CYCLE_INITIAL_VALUES as CA_OFFCYCLE_INITIAL_VALUES } from "./OffCycle/countryConfigs/CA/initialValues";
import { COMISSION_INITIAL_VALUES as GB_COMISSION_INITIAL_VALUES } from "./Comission/countryConfigs/GB/initialValues";
import { OFF_CYCLE_INITIAL_VALUES as GB_OFFCYCLE_INITIAL_VALUES } from "./OffCycle/countryConfigs/GB/initialValues";
import { RETROACTIVE_INITIAL_VALUES as CA_RETROACTIVE_INITIAL_VALUES } from "./Retroactive/countryConfigs/CA/initialValues";
import { RETROACTIVE_INITIAL_VALUES as GB_RETROACTIVE_INITIAL_VALUES } from "./Retroactive/countryConfigs/GB/initialValues";
import { BONUS_INITIAL_VALUES as GB_BONUS_INITIAL_VALUES } from "./BonusPay/countryConfigs/GB/initialValues";
import { BONUS_INITIAL_VALUES as MX_BONUS_INITIAL_VALUES } from "./BonusPay/countryConfigs/MX/initialValues";
import { COMISSION_INITIAL_VALUES as MX_COMISSION_INITIAL_VALUES } from "./Comission/countryConfigs/MX/initialValues";
import { RETROACTIVE_INITIAL_VALUES as MX_RETROACTIVE_INITIAL_VALUES } from "./Retroactive/countryConfigs/MX/initialValues";
import { OFF_CYCLE_INITIAL_VALUES as MX_OFFCYCLE_INITIAL_VALUES } from "./OffCycle/countryConfigs/MX/initialValues";
import { BONUS_INITIAL_VALUES as ES_BONUS_INITIAL_VALUES } from "./BonusPay/countryConfigs/ES/initialValues";
import { COMISSION_INITIAL_VALUES as ES_COMISSION_INITIAL_VALUES } from "./Comission/countryConfigs/ES/initialValues";
import { RETROACTIVE_INITIAL_VALUES as ES_RETROACTIVE_INITIAL_VALUES } from "./Retroactive/countryConfigs/ES/initialValues";
import { OFF_CYCLE_INITIAL_VALUES as ES_OFFCYCLE_INITIAL_VALUES } from "./OffCycle/countryConfigs/ES/initialValues";
import { BONUS_INITIAL_VALUES as NL_BONUS_INITIAL_VALUES } from "./BonusPay/countryConfigs/NL/initialValues";
import { COMISSION_INITIAL_VALUES as NL_COMISSION_INITIAL_VALUES } from "./Comission/countryConfigs/NL/initialValues";
import { OFF_CYCLE_INITIAL_VALUES as NL_OFFCYCLE_INITIAL_VALUES } from "./OffCycle/countryConfigs/NL/initialValues";
import { RETROACTIVE_INITIAL_VALUES as NL_RETROACTIVE_INITIAL_VALUES } from "./Retroactive/countryConfigs/NL/initialValues";
import { BONUS_INITIAL_VALUES as PT_BONUS_INITIAL_VALUES } from "./BonusPay/countryConfigs/PT/initialValues";
import { COMISSION_INITIAL_VALUES as PT_COMISSION_INITIAL_VALUES } from "./Comission/countryConfigs/PT/initialValues";
import { OFF_CYCLE_INITIAL_VALUES as PT_OFFCYCLE_INITIAL_VALUES } from "./OffCycle/countryConfigs/PT/initialValues";
import { RETROACTIVE_INITIAL_VALUES as PT_RETROACTIVE_INITIAL_VALUES } from "./Retroactive/countryConfigs/PT/initialValues";
import { BONUS_INITIAL_VALUES as CO_BONUS_INITIAL_VALUES } from "./BonusPay/countryConfigs/CO/initialValues";
import { COMISSION_INITIAL_VALUES as CO_COMISSION_INITIAL_VALUES } from "./Comission/countryConfigs/CO/initialValues";
import { OFF_CYCLE_INITIAL_VALUES as CO_OFFCYCLE_INITIAL_VALUES } from "./OffCycle/countryConfigs/CO/initialValues";
import { RETROACTIVE_INITIAL_VALUES as CO_RETROACTIVE_INITIAL_VALUES } from "./Retroactive/countryConfigs/CO/initialValues";
import { BONUS_INITIAL_VALUES as IE_BONUS_INITIAL_VALUES } from "./BonusPay/countryConfigs/IE/initialValues";
import { COMISSION_INITIAL_VALUES as IE_COMISSION_INITIAL_VALUES } from "./Comission/countryConfigs/IE/initialValues";
import { RETROACTIVE_INITIAL_VALUES as IE_RETROACTIVE_INITIAL_VALUES } from "./Retroactive/countryConfigs/IE/initialValues";
import { OFF_CYCLE_INITIAL_VALUES as IE_OFFCYCLE_INITIAL_VALUES } from "./OffCycle/countryConfigs/IE/initialValues";
import { BONUS_INITIAL_VALUES as IN_BONUS_INITIAL_VALUES } from "./BonusPay/countryConfigs/IN/initialValues";
import { COMISSION_INITIAL_VALUES as IN_COMISSION_INITIAL_VALUES } from "./Comission/countryConfigs/IN/initialValues";
import { RETROACTIVE_INITIAL_VALUES as IN_RETROACTIVE_INITIAL_VALUES } from "./Retroactive/countryConfigs/IN/initialValues";
import { OFF_CYCLE_INITIAL_VALUES as IN_OFFCYCLE_INITIAL_VALUES } from "./OffCycle/countryConfigs/IN/initialValues";
import { BONUS_INITIAL_VALUES as AU_BONUS_INITIAL_VALUES } from "./BonusPay/countryConfigs/AU/initialValues";
import { COMISSION_INITIAL_VALUES as AU_COMISSION_INITIAL_VALUES } from "./Comission/countryConfigs/AU/initialValues";
import { RETROACTIVE_INITIAL_VALUES as AU_RETROACTIVE_INITIAL_VALUES } from "./Retroactive/countryConfigs/AU/initialValues";
import { OFF_CYCLE_INITIAL_VALUES as AU_OFFCYCLE_INITIAL_VALUES } from "./OffCycle/countryConfigs/AU/initialValues";
import { BONUS_INITIAL_VALUES as BR_BONUS_INITIAL_VALUES } from "./BonusPay/countryConfigs/BR/initialValues";
import { COMISSION_INITIAL_VALUES as BR_COMISSION_INITIAL_VALUES } from "./Comission/countryConfigs/BR/initialValues";
import { RETROACTIVE_INITIAL_VALUES as BR_RETROACTIVE_INITIAL_VALUES } from "./Retroactive/countryConfigs/BR/initialValues";
import { OFF_CYCLE_INITIAL_VALUES as BR_OFFCYCLE_INITIAL_VALUES } from "./OffCycle/countryConfigs/BR/initialValues";
import { BONUS_INITIAL_VALUES as PH_BONUS_INITIAL_VALUES } from "./BonusPay/countryConfigs/PH/initialValues";
import { COMISSION_INITIAL_VALUES as PH_COMISSION_INITIAL_VALUES } from "./Comission/countryConfigs/PH/initialValues";
import { RETROACTIVE_INITIAL_VALUES as PH_RETROACTIVE_INITIAL_VALUES } from "./Retroactive/countryConfigs/PH/initialValues";
import { OFF_CYCLE_INITIAL_VALUES as PH_OFFCYCLE_INITIAL_VALUES } from "./OffCycle/countryConfigs/PH/initialValues";

import { CountryConfigKeyType } from "./types";

const CONFIG_MAPS = {
  [CountryCode.CA]: {
    [EmployeePayType.BONUS]: CA_BONUS_INITIAL_VALUES,
    [EmployeePayType.COMISSION]: CA_COMISSION_INITIAL_VALUES,
    [EmployeePayType.OFF_CYCLE]: CA_OFFCYCLE_INITIAL_VALUES,
    [EmployeePayType.RETROACTIVE]: CA_RETROACTIVE_INITIAL_VALUES,
  },
  [CountryCode.GB]: {
    [EmployeePayType.BONUS]: GB_BONUS_INITIAL_VALUES,
    [EmployeePayType.COMISSION]: GB_COMISSION_INITIAL_VALUES,
    [EmployeePayType.OFF_CYCLE]: GB_OFFCYCLE_INITIAL_VALUES,
    [EmployeePayType.RETROACTIVE]: GB_RETROACTIVE_INITIAL_VALUES,
  },
  [CountryCode.MX]: {
    [EmployeePayType.BONUS]: MX_BONUS_INITIAL_VALUES,
    [EmployeePayType.COMISSION]: MX_COMISSION_INITIAL_VALUES,
    [EmployeePayType.OFF_CYCLE]: MX_OFFCYCLE_INITIAL_VALUES,
    [EmployeePayType.RETROACTIVE]: MX_RETROACTIVE_INITIAL_VALUES,
  },
  [CountryCode.ES]: {
    [EmployeePayType.BONUS]: ES_BONUS_INITIAL_VALUES,
    [EmployeePayType.COMISSION]: ES_COMISSION_INITIAL_VALUES,
    [EmployeePayType.OFF_CYCLE]: ES_OFFCYCLE_INITIAL_VALUES,
    [EmployeePayType.RETROACTIVE]: ES_RETROACTIVE_INITIAL_VALUES,
  },
  [CountryCode.NL]: {
    [EmployeePayType.BONUS]: NL_BONUS_INITIAL_VALUES,
    [EmployeePayType.COMISSION]: NL_COMISSION_INITIAL_VALUES,
    [EmployeePayType.OFF_CYCLE]: NL_OFFCYCLE_INITIAL_VALUES,
    [EmployeePayType.RETROACTIVE]: NL_RETROACTIVE_INITIAL_VALUES,
  },
  [CountryCode.PT]: {
    [EmployeePayType.BONUS]: PT_BONUS_INITIAL_VALUES,
    [EmployeePayType.COMISSION]: PT_COMISSION_INITIAL_VALUES,
    [EmployeePayType.OFF_CYCLE]: PT_OFFCYCLE_INITIAL_VALUES,
    [EmployeePayType.RETROACTIVE]: PT_RETROACTIVE_INITIAL_VALUES,
  },
  [CountryCode.CO]: {
    [EmployeePayType.BONUS]: CO_BONUS_INITIAL_VALUES,
    [EmployeePayType.COMISSION]: CO_COMISSION_INITIAL_VALUES,
    [EmployeePayType.OFF_CYCLE]: CO_OFFCYCLE_INITIAL_VALUES,
    [EmployeePayType.RETROACTIVE]: CO_RETROACTIVE_INITIAL_VALUES,
  },
  [CountryCode.IE]: {
    [EmployeePayType.BONUS]: IE_BONUS_INITIAL_VALUES,
    [EmployeePayType.COMISSION]: IE_COMISSION_INITIAL_VALUES,
    [EmployeePayType.OFF_CYCLE]: IE_OFFCYCLE_INITIAL_VALUES,
    [EmployeePayType.RETROACTIVE]: IE_RETROACTIVE_INITIAL_VALUES,
  },
  [CountryCode.IN]: {
    [EmployeePayType.BONUS]: IN_BONUS_INITIAL_VALUES,
    [EmployeePayType.COMISSION]: IN_COMISSION_INITIAL_VALUES,
    [EmployeePayType.OFF_CYCLE]: IN_OFFCYCLE_INITIAL_VALUES,
    [EmployeePayType.RETROACTIVE]: IN_RETROACTIVE_INITIAL_VALUES,
  },
  [CountryCode.AU]: {
    [EmployeePayType.BONUS]: AU_BONUS_INITIAL_VALUES,
    [EmployeePayType.COMISSION]: AU_COMISSION_INITIAL_VALUES,
    [EmployeePayType.OFF_CYCLE]: AU_OFFCYCLE_INITIAL_VALUES,
    [EmployeePayType.RETROACTIVE]: AU_RETROACTIVE_INITIAL_VALUES,
  },
  [CountryCode.BR]: {
    [EmployeePayType.BONUS]: BR_BONUS_INITIAL_VALUES,
    [EmployeePayType.COMISSION]: BR_COMISSION_INITIAL_VALUES,
    [EmployeePayType.OFF_CYCLE]: BR_OFFCYCLE_INITIAL_VALUES,
    [EmployeePayType.RETROACTIVE]: BR_RETROACTIVE_INITIAL_VALUES,
  },
  [CountryCode.PH]: {
    [EmployeePayType.BONUS]: PH_BONUS_INITIAL_VALUES,
    [EmployeePayType.COMISSION]: PH_COMISSION_INITIAL_VALUES,
    [EmployeePayType.OFF_CYCLE]: PH_OFFCYCLE_INITIAL_VALUES,
    [EmployeePayType.RETROACTIVE]: PH_RETROACTIVE_INITIAL_VALUES,
  },
};

export const loadFormInitialValues = (
  type: EmployeePayType,
  country: CountryConfigKeyType
) => {
  const configs = CONFIG_MAPS[country][type];
  return configs;
};

import styles from "./FormReviewPage.module.css";
import { Formik, Form } from "formik";
import { Form8973Context } from "../../contexts";
import { useContext, useState } from "react";
import { Footer } from "../Footer/Footer";
import FormHeader from "../FormHeader/FormHeader";
import { WORKFLOW_STEPS } from "../../constants";
import ReviewComparison from "../ReviewComparison/ReviewComparison";
import { useProcessForm8973Answers } from "../../hooks/useProcessForm8973Answers";
import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";
import { ErrorAlert } from "../ErrorAlert/ErrorAlert";

const { formStepContentContainer, formStepContent, reviewInfo, hiddenContent } =
  styles;

const FormReviewPageContent = () => (
  <>
    <FormHeader />
    <div className={formStepContentContainer}>
      <div className={formStepContent}>
        <h2 className={reviewInfo}>Review the changes</h2>
        <ReviewComparison />
      </div>
    </div>
  </>
);

const FormReviewPage: React.FC = () => {
  const {
    companyValues,
    formValues,
    setStep,
    setPreviousStep,
    setEmbeddedSignUrl,
  } = useContext(Form8973Context);
  const { processForm8973Answers, loading } = useProcessForm8973Answers();
  const [error, setError] = useState<string | null>(null);

  const handleFormSubmit = async () => {
    setError(null);

    await processForm8973Answers({
      formValues,
      companyValues,
      onSuccess: (processData) => {
        if (processData.embeddedSignUrl) {
          setEmbeddedSignUrl(processData.embeddedSignUrl);
          setStep(WORKFLOW_STEPS.SIGN_DOCUMENT);
          setPreviousStep(WORKFLOW_STEPS.REVIEW);
        } else {
          setStep(WORKFLOW_STEPS.CONFIRMATION_RECEIVED);
          setPreviousStep(WORKFLOW_STEPS.REVIEW);
        }
      },
      onError: (message) => setError(message),
      onEmbeddedSignUrl: (url) => setEmbeddedSignUrl(url),
    });
  };

  return (
    <Formik initialValues={formValues} onSubmit={handleFormSubmit}>
      {() => (
        <Form>
          {loading && <LoadingSpinner />}
          {error && <ErrorAlert error={error} />}
          <div className={loading ? hiddenContent : ""}>
            <FormReviewPageContent />
          </div>
          <Footer />
        </Form>
      )}
    </Formik>
  );
};

export default FormReviewPage;

import { Alert, Button, Card, PageHeader } from "@justworkshr/milo-core";
import {
  ActionFooter,
  Form,
  FormField,
  MultiSelect,
  MultiSelectItem,
  Select,
  SelectOption,
  TextInput,
} from "@justworkshr/milo-form";
import { Formik, type FormikProps, type FormikValues } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import Layout from "../../../Layout";
import onboardingStyles from "../../../Onboarding.module.css";
import useOnboardingForm from "../../../hooks/useOnboardingForm";
import styles from "../../ProfileInfo.module.css";
import { NATIONALITIES } from "../../constants";
import { compactTaxId } from "../../taxIdUtils";
import type { ProfileInfoFormTypeIN as ProfileInfoFormType } from "../../types";
import BaseFormFragment, {
  initialValues as baseInitialValues,
  schema as baseSchema,
} from "../base/BaseFormFragment";
import IdentifierNumberFormFragment, {
  initialValues as identifierNumberInitialValues,
  schema as identifierNumberSchema,
} from "../identifier-input/IdentifierNumberFormFragment";

const { inputField } = styles;
const STATE_KEY = "profile-info";

export default function ProfileInfoIN() {
  const { t } = useTranslation();
  const {
    loading,
    memberData: { workCountry },
    form: { submitting, showErrorAlert, errorAlertMessage, onSubmit },
    memberOnboarding: {
      stepNumber,
      redirecting,
      profileInfo,
      idVerificationStatus,
    },
  } = useOnboardingForm(STATE_KEY);

  const onFormSubmit = (values: FormikValues) => {
    const profileInfoIdentifierNumber = compactTaxId(
      workCountry,
      values.identifierNumber
    );

    onSubmit({
      profileInfo: {
        ...values,
        confirmIdentifierNumber: undefined,
        identifierNumber: profileInfoIdentifierNumber,
        inYearToDateTaxableSalary: values.inYearToDateTaxableSalary || null,
        inYearToDateExemptions: values.inYearToDateExemptions || null,
      },
    });
  };

  const profileInfoSchema = Yup.object({
    nationality: Yup.string()
      .oneOf(NATIONALITIES.map((nationality) => nationality.value))
      .required("Required."),
    inPfUan: Yup.string()
      .required("Required.")
      .matches(/^[A-Z]{5}[0-9]{17}$/, t("Invalid PF UAN.")),
    inEsic: Yup.string()
      .optional()
      .matches(/^[0-9]{17}$/, t("Invalid ESIC.")),
    inYearToDateTaxableSalary: Yup.number().optional().min(0),
    inYearToDateExemptions: Yup.array().of(Yup.string()).optional(),
    inYearToDateTds: Yup.number().required("Required.").min(0),
    inTaxRegime: Yup.string().oneOf(["old", "new"]).required("Required."),
  })
    .concat(baseSchema(t))
    .concat(identifierNumberSchema(t, "IN"));

  const initialValuesIN = {
    nationality: "",
    inPfUan: "",
    inEsic: "",
    inYearToDateTaxableSalary: "",
    inYearToDateExemptions: [],
    inYearToDateTds: "",
    inTaxRegime: "",
  };

  const getInitialValues = (): ProfileInfoFormType["profileInfo"] => {
    const initialValues = {
      ...baseInitialValues,
      ...identifierNumberInitialValues,
    };

    const defaultInitialValues = profileInfo?.profileInfo || initialValues;

    const mergedValues: ProfileInfoFormType["profileInfo"] = {
      ...initialValuesIN,
      ...defaultInitialValues,
    };

    return mergedValues;
  };

  const schema = profileInfoSchema;

  return (
    <Layout
      step={stepNumber}
      loading={loading || redirecting}
      idVerificationStatus={idVerificationStatus}
    >
      <>
        <Alert color="destructive" visible={showErrorAlert}>
          {errorAlertMessage ||
            t("An error occurred while submitting your profile information.")}
        </Alert>

        <PageHeader title={t("Complete your profile")} />

        <Formik
          initialValues={getInitialValues()}
          onSubmit={onFormSubmit}
          validationSchema={schema}
        >
          {({
            errors,
            handleBlur,
            handleSubmit,
            handleChange,
            touched,
            values,
            setFieldValue,
          }: FormikProps<ProfileInfoFormType["profileInfo"]>) => {
            const fieldErrorHandler = (
              name: keyof ProfileInfoFormType["profileInfo"]
            ) => {
              if (!errors || !touched) return;
              if (errors[name] && touched[name]) {
                return errors[name]?.toString();
              }
            };

            return (
              <Form onSubmit={handleSubmit}>
                <Card title={t("Personal information")}>
                  <BaseFormFragment />

                  {/* IN specific fields */}
                  <div className={inputField}>
                    <FormField
                      name="nationality"
                      required
                      label={t("Nationality")}
                      error={touched?.nationality ? errors?.nationality : ""}
                    >
                      <Select
                        name="nationality"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.nationality}
                        placeholder="Select Nationality..."
                      >
                        {NATIONALITIES.map((country) => (
                          <SelectOption
                            value={country?.value}
                            key={country?.value}
                          >
                            {country?.description}
                          </SelectOption>
                        ))}
                      </Select>
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      name="inPfUan"
                      label={t(
                        "Provident Fund Universal Account Number (PF UAN)"
                      )}
                      error={fieldErrorHandler("inPfUan")}
                      required
                    >
                      <TextInput
                        name="inPfUan"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={String(values.inPfUan)}
                      />
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      name="inEsic"
                      label={t(
                        "Employee’s State Insurance Corporation (ESIC) Number"
                      )}
                      error={fieldErrorHandler("inEsic")}
                    >
                      <TextInput
                        name="inEsic"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={String(values.inEsic)}
                      />
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      name="inYearToDateTaxableSalary"
                      label={t("Year-to-date taxable salary (INR)")}
                      error={fieldErrorHandler("inYearToDateTaxableSalary")}
                    >
                      <TextInput
                        name="inYearToDateTaxableSalary"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={String(values.inYearToDateTaxableSalary)}
                      />
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      name="inYearToDateExemptions"
                      label={t("Year-to-date exemptions (INR)")}
                      error={fieldErrorHandler("inYearToDateExemptions")}
                    >
                      <MultiSelect
                        values={values.inYearToDateExemptions}
                        onChange={(selectedItems: MultiSelectItem[]) => {
                          const selectedValues = selectedItems.map(
                            (item) => item.value as string
                          );
                          setFieldValue(
                            "inYearToDateExemptions",
                            selectedValues
                          );
                        }}
                        onBlur={handleBlur}
                      >
                        <SelectOption value="professional_tax">
                          {t("Professional tax")}
                        </SelectOption>
                        <SelectOption value="house_rent_allowance">
                          {t("House Rent Allowance (HRA)")}
                        </SelectOption>
                        <SelectOption value="leave_and_travel_allowance">
                          {t("Leave and Travel allowance")}
                        </SelectOption>
                        <SelectOption value="special_allowance">
                          {t("Special Allowance")}
                        </SelectOption>
                      </MultiSelect>
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      required
                      name="inYearToDateTds"
                      label={t("Year-to-date TDS (INR)")}
                      error={fieldErrorHandler("inYearToDateTds")}
                    >
                      <TextInput
                        name="inYearToDateTds"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={String(values.inYearToDateTds)}
                      />
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      required
                      name="inTaxRegime"
                      label={t("Tax regime")}
                      error={fieldErrorHandler("inTaxRegime")}
                    >
                      <Select
                        name="inTaxRegime"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={String(values.inTaxRegime)}
                        placeholder="Select Tax Regime"
                      >
                        <SelectOption value="new">
                          {t("New tax regime")}
                        </SelectOption>
                        <SelectOption value="old">
                          {t("Old tax regime")}
                        </SelectOption>
                      </Select>
                    </FormField>
                  </div>

                  {/* END IN specific fields */}

                  <IdentifierNumberFormFragment workCountry="IN" />
                </Card>

                <ActionFooter
                  className={onboardingStyles.footer}
                  actions={[
                    <Button
                      color="brand"
                      key="submit-btn"
                      loading={submitting}
                      type="submit"
                    >
                      {t("Save & continue")}
                    </Button>,
                  ]}
                />
              </Form>
            );
          }}
        </Formik>
      </>
    </Layout>
  );
}

import React, { useEffect, useState } from "react";
import { Card } from "@justworkshr/milo-core";
import { CheckboxInput } from "@justworkshr/milo-form";
import { ContactInfoFormType } from "./types";
import { useFormikContext } from "formik";
import styles from "./ContactInfo.module.css";
import AddressForm from "../../components/InternationalAddressForm/AddressForm";
import { useTranslation } from "react-i18next";

type Props = {
  handleBlur: (event: React.FocusEvent) => void;
  handleChange: (event: React.ChangeEvent) => void;
};

const MailingAddressCard = ({ handleBlur, handleChange }: Props) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<ContactInfoFormType>();

  const [sameAddress, setSameAddress] = useState(false);
  const formPath = "contactInfo.mailingAddress";

  useEffect(() => {
    if (sameAddress) {
      // Assign all home address values to mailing address
      Object.entries(values.contactInfo.homeAddress).forEach(([key, value]) => {
        setFieldValue(`${formPath}.${key}`, value);
      });
    }
  }, [sameAddress, values.contactInfo.homeAddress, setFieldValue]);

  return (
    <Card title={t("Mailing Address")}>
      <div className={styles.sameAddressCheckbox}>
        <CheckboxInput
          name="sameAddress"
          label={t("Same as home address")}
          onChange={() => setSameAddress((oldState) => !oldState)}
          checked={sameAddress}
          required={false}
          value={sameAddress ? 1 : 0}
        />
      </div>
      <div className={sameAddress ? styles.hideableWrapper : ""}>
        <AddressForm
          handleBlur={handleBlur}
          handleChange={handleChange}
          formPath={formPath}
        />
      </div>
    </Card>
  );
};

export default MailingAddressCard;

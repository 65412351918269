import {
  compact as originalCompact,
  format as originalFormat,
  name as originalName,
  localName as originalLocalName,
  abbreviation as originalAbbreviation,
} from "stdnum/src/ie/pps";
import { ValidateReturn } from "stdnum/lib/cjs/types";
import * as exceptions from "stdnum/src/exceptions";
import { strings } from "stdnum/src/util";

function clean(input: string): ReturnType<typeof strings.cleanUnicode> {
  return strings.cleanUnicode(input, " -");
}

function invalid(error: Error): ValidateReturn {
  return { isValid: false, error };
}

function checkMOD23(value: string): boolean {
  const len = value.length;

  const total =
    Array.from(value.slice(0, 7)).reduce(
      (acc, digit, index) => acc + parseInt(digit, 10) * (8 - index),
      0
    ) + (len === 9 ? (value.charCodeAt(8) - 64) * 9 : 0);

  const mod = total % 23 || 23;

  return String.fromCharCode(64 + mod) === value[7].toUpperCase();
}

const ppsRe = /^\d{7}[A-W][ABHWTX]?$/;

const ieTaxIdValidator = {
  name: originalName,
  localName: originalLocalName,
  abbreviation: originalAbbreviation,

  compact(input: string): string {
    return originalCompact(input);
  },

  format(input: string): string {
    return originalFormat(input);
  },

  validate(input: string): ValidateReturn {
    const [value, error] = clean(input);

    if (error) {
      return invalid(error);
    }
    if (value.length !== 8 && value.length !== 9) {
      return invalid(new exceptions.InvalidLength());
    }
    if (!ppsRe.test(value)) {
      return invalid(new exceptions.InvalidFormat());
    }
    if (!checkMOD23(value)) {
      return invalid(new exceptions.InvalidChecksum());
    }

    return {
      isValid: true,
      compact: value,
      isIndividual: true,
      isCompany: false,
    };
  },
};

export { ieTaxIdValidator };

import { CONTRACTORS_PATH, EMPLOYEES_EOR_PATH, OVERVIEW_PATH } from "./routes";

export const TABS = [
  { path: OVERVIEW_PATH, name: "Overview" },
  { path: EMPLOYEES_EOR_PATH, name: "Employer of Record" },
  { path: CONTRACTORS_PATH, name: "Contractors" },
];

export const CF_INVITATION_CENTER = "/cf/invitation-center";
export const CF_ADD_CONTRACTOR_LINK = `${CF_INVITATION_CENTER}#contractors-or-vendors`;
export const CF_ADD_EMPLOYEES_LINK = `${CF_INVITATION_CENTER}#employees`;

export const CF_COST_CALCULATOR = `https://docs.google.com/spreadsheets/d/1N5RYTHrEsQBhKDdrxtLXOtmycJ4s1tET2neWTS3f6Y8/edit?gid=0#gid=0`;
export const INTERNATIONAL_CONTRACTORS_PRODUCT_HELP_LINK =
  "https://help.justworks.com/hc/en-us/articles/18040350606619-International-Contractors";
export const INTERNATIONAL_PRODUCTS_HELP_LINK =
  "https://help.justworks.com/hc/en-us/sections/24025565585179-International-Products";

export const EOR_PRICE = "$599/month per employee";
export const CONTRACTOR_PRICE = "$39 /paid contractor /month";

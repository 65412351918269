import { WORKFLOW_STEPS_NUMBER_LABEL, defaultFormValues } from "../constants";
import { Form8973Type, FileInputType, FormikFormValuesType } from "../types";
import { NATIONALITIES } from "pages/employer-of-record/onboarding/profile-info/constants";

// Function to get the step number by label
export const getStepNumber = (stepLabel: string): number | undefined => {
  return WORKFLOW_STEPS_NUMBER_LABEL[stepLabel];
};

export const mapCompanyDataToInitialValues = (companyData: Form8973Type) => ({
  uuid: companyData?.companyUuid || "",
  ein: companyData?.companyEin || "",
  legalEntityName: companyData?.companyLegalEntityName || "",
  tradeName: companyData?.companyTradeName || "",
  addressLine1: companyData?.companyPrimaryAddress?.street1 || "",
  addressLine2: companyData?.companyPrimaryAddress?.street2 || "",
  addressCity: companyData?.companyPrimaryAddress?.city || "",
  addressState: companyData?.companyPrimaryAddress?.state || "",
  addressZip: companyData?.companyPrimaryAddress?.postalCode || "",
  addressCountry: companyData?.companyPrimaryAddress?.countryCode || "",
});

const nationalitiesMap = NATIONALITIES.reduce((acc, nationality) => {
  acc[nationality.value] = nationality.description;
  return acc;
}, {} as Record<string, string>);

export const getCountryDescription = (
  countryCode: string
): string | undefined => {
  return nationalitiesMap[countryCode];
};

export const createAddress = (values: Record<string, string>) => {
  const addressLine1 =
    "addressLine1" in values
      ? values.addressLine1
      : values.correctedAddressLine1;
  const addressLine2 =
    "addressLine2" in values
      ? values.addressLine2
      : values.correctedAddressLine2;
  const addressState =
    "addressState" in values
      ? values.addressState
      : values.correctedAddressState;
  const addressCity =
    "addressCity" in values ? values.addressCity : values.correctedAddressCity;
  const addressZip =
    "addressZip" in values ? values.addressZip : values.correctedAddressZip;
  const addressCountry =
    "addressCountry" in values
      ? values.addressCountry
      : values.correctedAddressCountry;

  const countryFullName =
    getCountryDescription(addressCountry) || addressCountry;

  return `${addressLine1}${
    addressLine2 ? `, ${addressLine2}` : ""
  }, ${addressCity}, ${addressState} ${addressZip}, ${countryFullName}`;
};

async function readAsDataURL(file: File): Promise<string | undefined> {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onerror = reject;
    fr.onload = () => {
      resolve(fr.result?.toString());
    };
    fr.readAsDataURL(file);
  });
}

async function processFileObject(file: File): Promise<FileInputType> {
  const dataUrl = await readAsDataURL(file);
  return {
    dataUrl,
    file,
  };
}

export const formatFileData = async (fileInput: File | string | null) => {
  const formattedFile =
    fileInput instanceof File ? await processFileObject(fileInput) : null;
  return {
    contentType:
      formattedFile?.file instanceof File ? formattedFile.file.type : "",
    originalFilename:
      formattedFile?.file instanceof File ? formattedFile.file.name : "",
    blobFile: formattedFile?.dataUrl || "",
  };
};

export const filterFormValues = (values: FormikFormValuesType) => {
  const defaultKeys = Object.keys(
    defaultFormValues
  ) as (keyof FormikFormValuesType)[];

  const filteredValues: Partial<FormikFormValuesType> = {};

  defaultKeys.forEach((key) => {
    if (key in values) {
      if (values[key] !== null) {
        filteredValues[key] = values[key] as string | undefined;
      }
    }
  });

  return filteredValues as FormikFormValuesType;
};

export const formatEinValue = (ein: string) =>
  ein.length === 9 ? `${ein.slice(0, 2)}-${ein.slice(2)}` : ein;

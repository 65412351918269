import styles from "./FormStepTwo.module.css";
import { useContext } from "react";
import { Form8973Context } from "../../contexts";
import { WORKFLOW_STEPS } from "../../constants";
import { Formik, Form } from "formik";
import { stepTwoValidationSchema as getValidationSchema } from "../validationSchema";
import { Footer } from "../Footer/Footer";
import FormHeader from "../FormHeader/FormHeader";
import RenderInput from "../RenderInput/RenderInput";
import { GENERIC_RADIO_OPTIONS } from "../../constants";
import { createAddress, filterFormValues } from "../../utils";
import { NATIONALITIES } from "pages/employer-of-record/onboarding/profile-info/constants";
import { useProcessForm8973Answers } from "../../hooks/useProcessForm8973Answers";
import { FormikFormValuesType } from "../../types";
import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";
import { ErrorAlert } from "../ErrorAlert/ErrorAlert";
import { useState } from "react";

const { formStepContent, reviewInfo, formStepContentContainer, hiddenContent } =
  styles;

const FormStepTwo: React.FC = () => {
  const {
    setStep,
    setPreviousStep,
    formValues,
    setFormValues,
    companyValues,
    setEmbeddedSignUrl,
  } = useContext(Form8973Context);
  const [error, setError] = useState<string | null>(null);
  const { processForm8973Answers, loading } = useProcessForm8973Answers();
  setPreviousStep(WORKFLOW_STEPS.STEP_ONE);

  const getFieldsToSave = (
    values: FormikFormValuesType
  ): FormikFormValuesType => {
    const updatedValues = { ...filterFormValues(values) };

    // Clear Trade Name fields
    if (values.tradeNameValid === "true") {
      updatedValues.correctedTradeName = "";
    }

    // Clear Address fields
    if (values.addressValid === "true") {
      updatedValues.correctedAddressLine1 = "";
      updatedValues.correctedAddressLine2 = "";
      updatedValues.correctedAddressCity = "";
      updatedValues.correctedAddressState = "";
      updatedValues.correctedAddressZip = "";
      updatedValues.correctedAddressCountry = "";
    }
    return updatedValues;
  };

  const handleFormSubmit = async (values: FormikFormValuesType) => {
    setError(null);
    const updatedValues = getFieldsToSave(values);

    if (
      updatedValues.einValid === "true" &&
      updatedValues.legalEntityNameValid === "true" &&
      updatedValues.addressValid === "true" &&
      updatedValues.tradeNameValid === "true"
    ) {
      setFormValues(updatedValues);
      await processForm8973Answers({
        formValues: updatedValues,
        companyValues,
        onSuccess: () => {
          setStep(WORKFLOW_STEPS.SIGN_DOCUMENT);
          setPreviousStep(WORKFLOW_STEPS.STEP_TWO);
        },
        onError: (message) => console.error(message),
        onEmbeddedSignUrl: (url) => {
          setEmbeddedSignUrl(url);
        },
      });
    } else {
      setFormValues(updatedValues);
      setStep(WORKFLOW_STEPS.REVIEW_PAGE);
      setPreviousStep(WORKFLOW_STEPS.STEP_TWO);
    }
  };

  return (
    <Formik
      initialValues={formValues}
      validationSchema={getValidationSchema}
      onSubmit={handleFormSubmit}
    >
      {({ values }) => (
        <Form>
          <FormHeader />
          {loading && <LoadingSpinner />}
          {error && <ErrorAlert error={error} />}
          <div className={loading ? hiddenContent : ""}>
            <div className={formStepContentContainer}>
              <div className={formStepContent}>
                <h2 className={reviewInfo}>
                  Let’s review the info we have on file for your company:
                </h2>
                <div>
                  <RenderInput
                    name="tradeNameValid"
                    label={`Is your company trade name still ${companyValues.tradeName}?`}
                    type="radio"
                    options={GENERIC_RADIO_OPTIONS}
                    required
                  />
                  {values.tradeNameValid === "false" && (
                    <RenderInput
                      name="correctedTradeName"
                      label="Enter correct trade name"
                      type="text"
                      required
                    />
                  )}
                  <RenderInput
                    name="addressValid"
                    label={`Is your company still located at ${createAddress(
                      companyValues
                    )}?`}
                    type="radio"
                    options={GENERIC_RADIO_OPTIONS}
                    required
                  />
                  {values.addressValid === "false" && (
                    <>
                      <RenderInput
                        name="correctedAddressLine1"
                        label="Address line 1"
                        type="text"
                        required
                      />
                      <RenderInput
                        name="correctedAddressLine2"
                        label="Address line 2"
                        type="text"
                      />
                      <RenderInput
                        name="correctedAddressCity"
                        label="City"
                        type="text"
                        required
                      />
                      <RenderInput
                        name="correctedAddressState"
                        label="State"
                        type="text"
                        required
                      />
                      <RenderInput
                        name="correctedAddressZip"
                        label="Zip code"
                        type="text"
                        required
                      />
                      <RenderInput
                        name="correctedAddressCountry"
                        label="Country"
                        type="select"
                        options={NATIONALITIES.map((nationality) => ({
                          label: nationality.description,
                          value: nationality.value,
                        }))}
                        required
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </Form>
      )}
    </Formik>
  );
};

export default FormStepTwo;

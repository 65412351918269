import { FormField } from "@justworkshr/milo-form";
import { getIn, useFormikContext } from "formik";
import { Zone } from "@shopify/address";
import React from "react";
import AddressFieldElement from "./AddressFieldElement";
import styles from "./AddressForm.module.css";
import { SupportedCountriesData } from "pages/employer-of-record/contexts/supportedCountriesContext";
import { InternationalAddressInput } from "types/generated/operations";

type Props = {
  fieldName: string;
  label: string;
  handleBlur: (event: React.FocusEvent) => void;
  handleChange: (event: React.ChangeEvent) => void;
  zones: Zone[] | undefined;
  formPath: string;
  supportedCountriesData: SupportedCountriesData;
};

const optionalFields = ["address2"];

const EORFieldMap: Record<string, keyof InternationalAddressInput> = {
  country: "countryCode",
  address1: "address1",
  address2: "address2",
  city: "city",
  zip: "postalCode",
  zone: "zoneCode",
  postalCode: "postalCode",
};

const AddressFormField = ({
  label,
  fieldName,
  handleBlur,
  handleChange,
  zones,
  formPath,
  supportedCountriesData,
}: Props) => {
  const { touched, errors } = useFormikContext<
    | Record<string, InternationalAddressInput>
    | Record<string, Record<string, InternationalAddressInput>>
  >();
  const EORAddressFieldName = EORFieldMap[fieldName];

  const touchedField = getIn(getIn(touched, formPath), EORAddressFieldName);

  const errorField = getIn(getIn(errors, formPath), EORAddressFieldName);

  return (
    <div
      className={`${styles.formFieldWidth} ${
        fieldName === "postalCode" ? styles.postal : ""
      }`}
    >
      <FormField
        name={`${formPath}.${EORAddressFieldName}`}
        label={label ?? fieldName}
        required={!optionalFields.includes(fieldName)}
        error={touchedField ? errorField : ""}
      >
        <AddressFieldElement
          fieldName={EORAddressFieldName}
          zones={zones}
          handleBlur={handleBlur}
          handleChange={handleChange}
          formPath={formPath}
          supportedCountriesData={supportedCountriesData}
        />
      </FormField>
    </div>
  );
};

export default AddressFormField;

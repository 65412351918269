type ValuedValidation = {
  value: number;
  message: string;
};

type BooleanValidation = {
  enabled: boolean;
  message: string;
};

type DateValidation = {
  value: Date;
  message: string;
};

// TODO: Do not add custom validations here
export type ValidationType = {
  number?: {
    max?: ValuedValidation;
    min?: ValuedValidation;
  };
  text?: {
    bankCode?: BooleanValidation;
    bsbCode?: BooleanValidation;
    clabe?: BooleanValidation;
    email?: BooleanValidation;
    iban?: BooleanValidation;
    ifsCode?: BooleanValidation;
    length?: ValuedValidation;
    number?: BooleanValidation;
    phoneNumber?: BooleanValidation;
    routingNumber?: BooleanValidation;
    sortCode?: BooleanValidation;
    swiftCode?: BooleanValidation;
    max?: ValuedValidation;
    min?: ValuedValidation;
    bankName?: BooleanValidation;
  };
  date?: {
    min?: DateValidation;
    max?: DateValidation;
  };
  required: BooleanValidation;
};

export enum FieldTypes {
  boolean = "boolean",
  text = "text",
  number = "number",
  date = "date",
  select = "select",
  group = "group",
  address = "address",
  phoneNumber = "phoneNumber",
}

type ArrayConfig = {
  limit: number;
  deletionStrategy?: "last" | "any";
};

export interface BaseFieldDataType {
  array?: ArrayConfig;
  informationType?: "address" | "contact" | "job";
  name: string;
  label?: string | null;
  message?: string;
  placeholder?: string;
  validations?: ValidationType;
  hideWhen?: Record<BaseFieldDataType["name"], (value: unknown) => boolean>;
  format?: (value: string) => string;
  compact?: (value: string) => string;
}

type TextFieldType = BaseFieldDataType & {
  type: FieldTypes.text;
};

type NumberFieldType = BaseFieldDataType & {
  type: FieldTypes.number;
};

type BooleanFieldType = BaseFieldDataType & {
  type: FieldTypes.boolean;
};

type DateFieldType = BaseFieldDataType & {
  type: FieldTypes.date;
};

type AddressFieldType = BaseFieldDataType & {
  type: FieldTypes.address;
};

export type PhoneNumberFieldType = BaseFieldDataType & {
  type: FieldTypes.phoneNumber;
  withoutPhoneNumberType?: boolean;
};

export type SelectFieldType = BaseFieldDataType & {
  type: FieldTypes.select;
  options: readonly { value: string | number; description: string }[];
};
export type RadioGroupFieldType = BaseFieldDataType & {
  type: FieldTypes.group;
  options: { value: string | number; description: string }[];
};

export type FieldDataType =
  | BooleanFieldType
  | TextFieldType
  | NumberFieldType
  | DateFieldType
  | SelectFieldType
  | RadioGroupFieldType
  | AddressFieldType
  | PhoneNumberFieldType;

export type ArrayFieldDataType = FieldDataType & { array: { limit: number } };

export type FieldData = FieldDataType & {
  fields?: FieldDataType[] | null;
};

export type FormType = {
  header: string;
  subheader?: string;
  fields: FieldData[];
  initialValues: {
    [key: string]: string | boolean | { [key: string]: string | boolean };
  };
};

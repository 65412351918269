import { ReactElement } from "react";
import { FormField, Select, SelectOption } from "@justworkshr/milo-form";
import MessageWithLink from "pages/employer-of-record/components/MessageWithLink/MessageWithLink";
import styles from "../../../../SetupFormGenerator.module.css";
import { GeneratedComponentProp, Option } from "../../../../types";
import { FormPayloadType } from "../../../../../../types";
import { SkeletonText } from "@justworkshr/milo-core";

export function SelectInput<T extends FormPayloadType = FormPayloadType>({
  field,
  accessor,
  touchedField,
  errorField,
  value,
  disabled,
  handleBlur,
  handleChange,
  elementId,
}: GeneratedComponentProp<T>): ReactElement {
  const options = field.options;

  return (
    <div className={`${errorField && touchedField ? styles.error : ""}`}>
      <FormField
        label={field.label}
        required={field.required}
        error={errorField && touchedField ? errorField : ""}
      >
        <>
          {field.message && (
            <div className={styles.description}>
              <MessageWithLink {...field.message} />
            </div>
          )}

          {options?.length === 0 && (
            <SkeletonText lineHeight="body" size="md" />
          )}

          {options && options.length > 0 && (
            <Select
              id={elementId}
              placeholder="Select..."
              value={value}
              disabled={disabled}
              onBlur={handleBlur}
              name={`setUp.${accessor}`}
              onChange={handleChange}
              style={
                disabled
                  ? { color: "var(--intent-color-text-neutral-default)" }
                  : {}
              }
            >
              {options.map((option: Option) => (
                <SelectOption key={option.value} value={option.value}>
                  {option.label}
                </SelectOption>
              ))}
            </Select>
          )}
        </>
      </FormField>
    </div>
  );
}

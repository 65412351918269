import { useProcessForm8973AnswersMutation } from "types/generated/operations";
import { formatFileData } from "../../utils";
import {
  CompanyValuesType,
  FormikFormValuesType,
  ProcessForm8973Response,
} from "../../types";

type ProcessForm8973Args = {
  formValues: FormikFormValuesType;
  companyValues: CompanyValuesType;
  onSuccess: (data: ProcessForm8973Response) => void;
  onError: (message: string) => void;
  onEmbeddedSignUrl?: (url: string) => void;
};

export const useProcessForm8973Answers = () => {
  const [processAnswers, { loading, error }] =
    useProcessForm8973AnswersMutation();

  const processForm8973Answers = async ({
    formValues,
    companyValues,
    onSuccess,
    onError,
    onEmbeddedSignUrl,
  }: ProcessForm8973Args) => {
    try {
      const formattedEinSupportingDocument = await formatFileData(
        formValues.einSupportingDocument
      );
      const formattedLegalEntityNameSupportingDocument = await formatFileData(
        formValues.legalEntityNameSupportingDocument
      );

      const response = await processAnswers({
        variables: {
          companyUuid: companyValues.uuid,
          form8973Answers: {
            ...formValues,
            einSupportingDocument: formattedEinSupportingDocument,
            legalEntityNameSupportingDocument:
              formattedLegalEntityNameSupportingDocument,
          },
        },
      });
      const processData = response?.data?.processForm8973Answers;

      if (processData?.success) {
        if (processData.embeddedSignUrl && onEmbeddedSignUrl) {
          onEmbeddedSignUrl(processData.embeddedSignUrl);
        }
        onSuccess(processData);
      } else if (processData?.error) {
        onError(processData.error.join(", "));
      }
    } catch (e) {
      onError("There was an error processing the form. Please try again.");
    }
  };

  return { processForm8973Answers, loading, error };
};

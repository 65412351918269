import React, { ReactElement } from "react";
import styles from "./HeaderStatus.module.css";
import { getStatusStyling } from "../utils";
import { HEADERSTATUS } from "../constants";
import { formatDate } from "pages/employer-of-record/employee-profile/utils";
import { useProfileContextData } from "pages/employer-of-record/employee-profile/contexts/ProfileInfoContext";
import { Button } from "@justworkshr/milo-core";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useGetFeatureFlag } from "lib/launch-darkly";

const HeaderStatus = (): ReactElement => {
  const { t } = useTranslation();
  const { employeeStatus, profileInfo } = useProfileContextData();
  const navigate = useNavigate();
  const { getFeatureFlag } = useGetFeatureFlag();

  const startDateChangesEnabled = getFeatureFlag(
    "release-change-eor-ee-start-date"
  );

  const contract = profileInfo?.eorEmployeeProfile.contract;
  const startDate = formatDate(contract?.startDate, "MMM D, YYYY");
  const endDate = formatDate(contract?.endDate, "MMM D, YYYY");
  const statusStyling = getStatusStyling(employeeStatus, styles);

  function changeStartDate() {
    navigate(
      `/employer-of-record/employee-profile/${profileInfo?.eorEmployeeProfile.personalInfo?.memberUuid}/start-date`
    );
  }

  return (
    <div className={`${styles.statusWrapper} ${statusStyling}`}>
      {employeeStatus === HEADERSTATUS.PENDING && (
        <div className={styles.startDate}>
          <div>
            <span className={styles.emphasize}>Start date: </span>
            {startDate}
          </div>
          {startDateChangesEnabled && (
            <Button size="sm" variant="outlined" onClick={changeStartDate}>
              {t("Change start date")}
            </Button>
          )}
        </div>
      )}
      {employeeStatus === HEADERSTATUS.SCHEDULED && (
        <div>
          <span className={styles.emphasize}>Scheduled for Separation</span>:
          The team will be in touch. For further changes or questions, contact{" "}
          <span className={styles.emphasize}>
            international-support@justworks.com
          </span>
        </div>
      )}
      {employeeStatus === HEADERSTATUS.TERMINATED && (
        <div>
          <span className={styles.emphasize}>Terminated</span> as of {endDate}.
          If you would like to rehire, contact{" "}
          <span className={styles.emphasize}>
            international-support@justworks.com
          </span>
        </div>
      )}
    </div>
  );
};

export default HeaderStatus;

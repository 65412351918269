import { ReactElement } from "react";
import styles from "../../InternationalSolutionsPage.module.css";
import { Box } from "@justworkshr/milo-core";
import { SystemIcon } from "@justworkshr/milo-icons";
import {
  CF_COST_CALCULATOR,
  INTERNATIONAL_PRODUCTS_HELP_LINK,
} from "pages/international-solutions/constants";

const ResourcesCard = (): ReactElement => {
  const { divWrapper, mdHead, mdGap } = styles;

  return (
    <Box
      backgroundColor="neutral"
      border={{
        borderColor: "neutralSubtle",
        borderRadius: "md",
        borderWidth: "md",
      }}
      padding="2xl"
    >
      <div className={`${divWrapper} ${mdGap}`}>
        <div className={`${divWrapper} ${mdGap}`}>
          <div className={mdHead}>Resources </div>
          <a href={CF_COST_CALCULATOR} target="_blank">
            Learn more about EOR hiring cost
            <span>
              {" "}
              <SystemIcon iconName="external-link" color="brand" size="small" />
            </span>
          </a>
          <a href={INTERNATIONAL_PRODUCTS_HELP_LINK} target="_blank">
            Learn more about our international products
            <span>
              {" "}
              <SystemIcon iconName="external-link" color="brand" size="small" />
            </span>
          </a>
        </div>
      </div>
    </Box>
  );
};

export default ResourcesCard;

import CollapsibleCard from "./CollapsibleCard";
import styles from "./EditContactInformation.module.css";
import { Divider } from "@justworkshr/milo-core";
import {
  EDIT_PROFILE_FIELDS,
  useInScopeFields,
} from "./CountrySpecific/edit-profile-autoform-config";
import { AutoFormField } from "pages/employer-of-record/components/AutoForm";

type Props = {
  open: boolean;
  toggleOpen: () => void;
};

export const CONTACT_FIELDS = [
  EDIT_PROFILE_FIELDS.phoneNumbers,
  EDIT_PROFILE_FIELDS.homeEmail,
  EDIT_PROFILE_FIELDS.workEmail,
  EDIT_PROFILE_FIELDS.emergencyContactFirstName,
  EDIT_PROFILE_FIELDS.emergencyContactLastName,
  EDIT_PROFILE_FIELDS.emergencyContactRelationship,
  EDIT_PROFILE_FIELDS.emergencyContactPhoneNumber,
];

export default function EditContactInformation(props: Props) {
  const { open, toggleOpen } = props;

  const workEmailFields = useInScopeFields([EDIT_PROFILE_FIELDS.workEmail]);

  return (
    <CollapsibleCard
      open={open}
      title="Contact Information"
      onClick={toggleOpen}
    >
      <div className={styles.title}>Contact</div>

      <div>
        <AutoFormField {...EDIT_PROFILE_FIELDS.phoneNumbers} />
        <div className={styles.emailsContainer}>
          <AutoFormField {...EDIT_PROFILE_FIELDS.homeEmail} />
          {!!workEmailFields[0] && (
            <AutoFormField {...EDIT_PROFILE_FIELDS.workEmail} />
          )}
        </div>
      </div>

      <Divider />

      <div className={styles.title}>Emergency contact</div>
      <AutoFormField {...EDIT_PROFILE_FIELDS.emergencyContactFirstName} />
      <AutoFormField {...EDIT_PROFILE_FIELDS.emergencyContactLastName} />
      <AutoFormField {...EDIT_PROFILE_FIELDS.emergencyContactRelationship} />
      <AutoFormField {...EDIT_PROFILE_FIELDS.emergencyContactPhoneNumber} />
    </CollapsibleCard>
  );
}

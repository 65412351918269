import { ReactElement, useState } from "react";
import styles from "../../InternationalSolutionsPage.module.css";
import { Box, Button } from "@justworkshr/milo-core";
import {
  CF_ADD_CONTRACTOR_LINK,
  CF_ADD_EMPLOYEES_LINK,
} from "pages/international-solutions/constants";
import { useNavigate } from "react-router-dom";
import {
  CONTRACTORS_PATH,
  EMPLOYEES_EOR_PATH,
} from "pages/international-solutions/routes";
import ThankYouDialog from "../Dialog/ThankYouDialog";
import {
  useGetCompanyDisplayQuery,
  useGetIsEmployerOfRecordEnabledQuery,
} from "types/generated/operations";
import { FLAG_NAMES, flagIsEnabled } from "lib/environment";
import { oktaAuth, pkceIsEnabled } from "app/okta";
import {
  sendCustomerIOEvent,
  CUSTOMER_IO_CTA_CLICK_EVENT,
} from "app/customer-io";

export interface InternationalSolutionsCardProps {
  intlSolutionType: string;
}
const InternationalSolutionsCard = ({
  intlSolutionType,
}: InternationalSolutionsCardProps): ReactElement => {
  const {
    divWrapper,
    smHead,
    hr,
    lgHead,
    mdFont,
    lgGap,
    countryList,
    countryBox,
    moreCountriesBox,
  } = styles;

  const navigate = useNavigate();

  const [showThankYouDialog, setShowThankYouDialog] = useState(false);
  const handleThankYouDialog = () => {
    setShowThankYouDialog((prev) => !prev);
  };

  const { data: eorEnabledData, loading } =
    useGetIsEmployerOfRecordEnabledQuery();
  const isEmployerOfRecordEnabled =
    eorEnabledData?.authenticatedMember.company.companySetting?.value ===
      "true" && flagIsEnabled(FLAG_NAMES.EMPLOYER_OF_RECORD);

  const accessToken = pkceIsEnabled() ? oktaAuth.getAccessToken() : "";
  const { data: memberData } = useGetCompanyDisplayQuery();

  const triggerCustomerIOEvent = (ctaName: string, nextUrl: string) => {
    sendCustomerIOEvent(
      {
        event_type: CUSTOMER_IO_CTA_CLICK_EVENT,
        member_uuids: [memberData?.authenticatedMember.uuid ?? ""],
        company_uuids: [memberData?.authenticatedMember.company.uuid ?? ""],
        data: {
          current_url: window.location.href,
          cta_name: ctaName,
          next_url: nextUrl,
        },
      },
      accessToken
    );
  };

  type CountryFlagMap = Record<string, string>;
  const countryFlagMap: CountryFlagMap = {
    Australia: "🇦🇺",
    India: "🇮🇳",
    Ireland: "🇮🇪",
    Portugal: "🇵🇹",
    Netherlands: "🇳🇱",
    Spain: "🇪🇸",
    "United Kingdom": "🇬🇧",
    Colombia: "🇨🇴",
    Philippines: "🇵🇭",
    Canada: "🇨🇦",
    Mexico: "🇲🇽",
    Brazil: "🇧🇷",
    Germany: "🇩🇪",
    France: "🇫🇷",
    Argentina: "🇦🇷",
    Austria: "🇦🇹",
  };
  interface Content {
    title: string;
    description: string;
    countryDescription: string;
    countries: Array<string>;
    moreCountries?: string;
  }
  interface CardContent {
    [key: string]: Content;
  }
  const cardContent: CardContent = {
    eor: {
      title: "Employer of Record (EOR)",
      description:
        "Justworks can help support your company as it expands globally through our Employer of Record (EOR) product. An EOR is a service provider that takes on the legal responsibilities and obligations of being an employer for a company's employees. Justworks’ EOR services are open to anyone who wants to streamline hiring full-time employees internationally (EOR services available in countries listed below). We are continually adding countries to make international hiring a breeze!",
      countryDescription: "The countries we own entity",
      countries: [
        "Australia",
        "Canada",
        "India",
        "Ireland",
        "Mexico",
        "Netherlands",
        "Portugal",
        "Spain",
        "United Kingdom",
        "Brazil",
        "Colombia",
        "Philippines",
      ],
    },
    intlContractors: {
      title: "International contractor payment",
      description:
        "Our International Contractor Payments allows customers to manage and pay international contractors in select countries (listed below). By using this feature, you can manage your international contractors in the same tool as your domestic contractors and employees. New countries are added frequently to our platform so you can manage and pay your contractors wherever they are based!",
      countryDescription: "The countries we can pay contractors",
      countries: [
        "Australia",
        "Canada",
        "India",
        "Germany",
        "France",
        "Mexico",
        "Philippines",
        "Portugal",
        "Spain",
        "United Kingdom",
        "Argentina",
        "Austria",
      ],
      moreCountries: "and 21 more",
    },
  };
  const { title, description, countryDescription, countries, moreCountries } =
    cardContent[intlSolutionType] || {};

  const renderButton = () => {
    switch (intlSolutionType) {
      case "eor":
        return (
          <div className={divWrapper}>
            <Button
              data-heap-id={
                isEmployerOfRecordEnabled
                  ? "eor-add-employees-button"
                  : "no-eor-im-interested-button"
              }
              color="brand"
              type="button"
              size="md"
              as={isEmployerOfRecordEnabled ? "a" : undefined}
              href={
                isEmployerOfRecordEnabled ? CF_ADD_EMPLOYEES_LINK : undefined
              }
              onClick={() => {
                if (!isEmployerOfRecordEnabled) {
                  triggerCustomerIOEvent(
                    "eor_overview_im_interested_button",
                    "thank-you-modal"
                  );
                  handleThankYouDialog();
                } else {
                  triggerCustomerIOEvent(
                    "eor_overview_add_employee_button",
                    CF_ADD_EMPLOYEES_LINK
                  );
                }
              }}
              loading={loading}
            >
              {isEmployerOfRecordEnabled ? "Add employees" : "I'm interested"}
            </Button>
            <Button
              color="brand"
              size="md"
              type="button"
              variant="ghost"
              onClick={() => {
                navigate(`..${EMPLOYEES_EOR_PATH}`);
              }}
            >
              Learn More
            </Button>
          </div>
        );
      case "intlContractors":
        return (
          <div className={divWrapper}>
            <Button
              data-heap-id="add-contractor-button"
              color="brand"
              type="button"
              as="a"
              size="md"
              href={CF_ADD_CONTRACTOR_LINK}
              onClick={() => {
                triggerCustomerIOEvent(
                  "eor_overview_add_contractor_button",
                  CF_ADD_CONTRACTOR_LINK
                );
              }}
            >
              Add contractors
            </Button>
            <Button
              color="brand"
              size="md"
              type="button"
              variant="ghost"
              onClick={() => {
                navigate(`..${CONTRACTORS_PATH}`);
              }}
            >
              Learn More
            </Button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Box
      backgroundColor="neutral"
      border={{
        borderColor: "neutralSubtle",
        borderRadius: "md",
        borderWidth: "md",
      }}
      padding="xl"
    >
      <div className={`${divWrapper} ${lgGap}`}>
        <div className={lgHead}>{title}</div>
        <div className={mdFont}>{description}</div>

        <hr className={hr}></hr>

        <div className={smHead}>{countryDescription}</div>
        <div className={`${countryList}`}>
          {countries.map((country, index) => (
            <div key={index} className={`${countryBox} `}>
              <span style={{ marginRight: "10px" }}>
                {countryFlagMap[country] || ""}{" "}
              </span>
              <p>{country}</p>
            </div>
          ))}
          <div className={`${moreCountriesBox} ${mdFont}`}>
            <p>{moreCountries}</p>
          </div>
        </div>

        {renderButton()}

        {showThankYouDialog && (
          <ThankYouDialog
            isOpen={showThankYouDialog}
            onClose={handleThankYouDialog}
          />
        )}
      </div>
    </Box>
  );
};

export default InternationalSolutionsCard;

import Row from "../../../Row/Row";
import { Card } from "@justworkshr/milo-core";
import BadgeTitle from "../BadgeTitle";
import { useProfileContextData } from "pages/employer-of-record/employee-profile/contexts/ProfileInfoContext";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { SupportedCountriesContext } from "pages/employer-of-record/contexts/supportedCountriesContext";
import EditAction from "../../../EditAction/EditAction";
import { esEducationalQualificationOptions } from "pages/employer-of-record/onboarding/profile-info/form/es/constants.es";
import { getDescription } from "pages/employer-of-record/utils";

function educationalQualificationKeyFromWorkCountry(workCountry?: string) {
  switch (workCountry) {
    case "ES":
      return "esEducationalQualification";
    case "BR":
      return "brEducationalQualification";
  }
}

function educationalQualificationOptions(workCountry?: string) {
  if (workCountry === "ES") return esEducationalQualificationOptions;
}

type Props = {
  editable: boolean;
};

export default function EducationInformation({ editable }: Props) {
  const { t } = useTranslation();
  const { profileInfo } = useProfileContextData();
  const { getCountryNameByCode } = useContext(SupportedCountriesContext);

  const personalInfo = profileInfo?.eorEmployeeProfile.personalInfo;
  const workCountry = profileInfo?.eorEmployeeProfile.employment?.workCountry;

  if (!personalInfo) throw new Error("No personal info");

  const educationalQualificationKey =
    educationalQualificationKeyFromWorkCountry(workCountry ?? undefined);

  if (!educationalQualificationKey) return null;

  const options = educationalQualificationOptions(workCountry ?? undefined);
  const educationalQualification = personalInfo[educationalQualificationKey];
  const displayValue = options
    ? getDescription(options, educationalQualification)
    : educationalQualification;

  return (
    <Card
      title={
        (
          <BadgeTitle
            title={t("Education")}
            badgeText={getCountryNameByCode(workCountry)}
          />
        ) as unknown as string
      }
      actions={[<EditAction target="education" enabled={editable} />]}
    >
      <Row label={t("Educational qualification")} value={displayValue} />
    </Card>
  );
}

import type { ChangeEvent, ReactElement } from "react";
import { useState } from "react";
import styles from "./IdentifierInput.module.css";
import { IconButton } from "@justworkshr/milo-core";
import { TextInput, TextInputProps } from "@justworkshr/milo-form";
import { compactTaxId, formatTaxId, taxIdMaxLength } from "../taxIdUtils";

const { obfuscatedInputWrapper } = styles;

export type IdentifierInputProps = Omit<TextInputProps, "type"> & {
  countryCode: string | undefined;
};

export const IdentifierInput = ({
  disabled = false,
  required = false,
  value = "",
  name = "",
  countryCode,
  onChange = () => {},
  ...props
}: IdentifierInputProps): ReactElement => {
  const [showAllInput, setShowAllInput] = useState(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.target.value = compactTaxId(countryCode, event.target.value);
    if (event.target.value.length <= taxIdMaxLength(countryCode)) {
      onChange(event);
    }
  };

  return (
    <div className={obfuscatedInputWrapper}>
      <TextInput
        {...props}
        name={name}
        value={
          showAllInput
            ? formatTaxId(countryCode, value)
            : compactTaxId(countryCode, value)
        }
        autoComplete={showAllInput ? "new-identifier" : props.autoComplete}
        data-heap-redact-text
        disabled={disabled}
        required={required}
        type={showAllInput ? "text" : "password"}
        onChange={handleChange}
      />
      <IconButton
        color="neutral"
        disabled={disabled}
        iconName={showAllInput ? "eye" : "eye-slash"}
        onClick={() => {
          setShowAllInput(!showAllInput);
        }}
        title={
          showAllInput ? `obfuscate ${name} input` : `unobfuscate ${name} input`
        }
        variant="ghost"
      />
    </div>
  );
};

import { Button } from "@justworkshr/milo-core";
import styles from "./EditProfile.module.css";
import { useState } from "react";
import { ActionFooter } from "@justworkshr/milo-form";
import { useTranslation } from "react-i18next";
import { EditableProfileInfoFormType } from "./types";
import { useNavigate, useParams } from "react-router-dom";
import {
  EorEmployeeProfileInput,
  GetEoREmployeeProfileQuery,
} from "types/generated/operations";
import { Form } from "@justworkshr/milo-form";
import { Formik } from "formik";
import EditIdentificationInformation, {
  IDENTIFICATION_FIELDS,
} from "./components/EditIdentificationInformation";
import EditContactInformation, {
  CONTACT_FIELDS,
} from "./components/EditContactInformation";
import { PHONE_TYPES } from "pages/employer-of-record/onboarding/contact-info/PhoneNumbersFragment";
import matches from "lodash/matches";
import moment from "moment";
import EditAddressInformation, {
  ADDRESS_FIELDS,
} from "./components/EditAddressInformation";
import { useApolloClient } from "@apollo/client";
import CancelEditModal from "./components/CancelEditModal";
import {
  AutoFormField,
  FieldDataType,
  generateFormValidations,
} from "pages/employer-of-record/components/AutoForm";
import {
  EDIT_PROFILE_FIELDS,
  initialValues,
  MemberDataField,
  useInScopeFields,
} from "./components/CountrySpecific/edit-profile-autoform-config";
import CollapsibleCard from "./components/CollapsibleCard";
import groupBy from "lodash/groupBy";
import omit from "lodash/omit";
import mapValues from "lodash/mapValues";

const sections = [
  "general",
  "address",
  "contact",
  "job",
  "contract",
  "education",
  "cartao",
  "family",
] as const;
export type Section = (typeof sections)[number];

type Props = {
  profile: GetEoREmployeeProfileQuery["eorEmployeeProfile"];
  onSubmit: (profileInput: EorEmployeeProfileInput) => void;
  onError: (message: string) => void;
  clearError: () => void;
  linkToProfile: string;
};

function encodeParams(queryParams: Record<string, string | boolean | number>) {
  return Object.entries(queryParams).map(
    ([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
  );
}

function getInitialValues(
  fields: MemberDataField[],
  profile: Props["profile"]
): EditableProfileInfoFormType {
  const { personalInfo, contactDetails, jobInformation } = profile;

  // Only show phone number types with values.
  const phoneNumbers: { type: string; value: string }[] = [];

  if (contactDetails?.mobilePhoneNumber?.value) {
    phoneNumbers.push({
      type: PHONE_TYPES.mobile,
      value: contactDetails.mobilePhoneNumber.value,
    });
  }

  if (contactDetails?.homePhoneNumber?.value) {
    phoneNumbers.push({
      type: PHONE_TYPES.home,
      value: contactDetails.homePhoneNumber.value,
    });
  }

  if (contactDetails?.workPhoneNumber?.value) {
    phoneNumbers.push({
      type: PHONE_TYPES.work,
      value: contactDetails.workPhoneNumber.value,
    });
  }

  return {
    preferredFirstName: personalInfo?.preferredFirstName ?? "",
    pronouns: personalInfo?.pronouns ?? "",
    dateOfBirth: personalInfo?.dateOfBirth ?? "",
    firstName: personalInfo?.firstName ?? "",
    middleName: personalInfo?.middleName ?? "",
    lastName: personalInfo?.lastName ?? "",
    workId: jobInformation?.workId ?? "",
    nationality: personalInfo?.nationality ?? "",
    ...initialValues(fields, profile),
  } as EditableProfileInfoFormType;
}

function EditableProfile({
  profile,
  onSubmit,
  onError,
  clearError,
  linkToProfile,
}: Props) {
  const client = useApolloClient();
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const [open, setOpen] = useState(
    Object.fromEntries(
      sections.map((section) => [
        section,
        window.location.hash === `#${section}`,
      ])
    ) as Record<Section, boolean>
  );
  const isAdmin = !!id;
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
  const identificationFields = useInScopeFields(IDENTIFICATION_FIELDS);
  const contactFields = useInScopeFields(CONTACT_FIELDS);
  const addressFields = useInScopeFields(ADDRESS_FIELDS);
  const jobFields = useInScopeFields([
    EDIT_PROFILE_FIELDS.workId,
    EDIT_PROFILE_FIELDS.departmentUuid,
    EDIT_PROFILE_FIELDS.managerUuid,
  ]);
  const contractDetailsFields = useInScopeFields([
    EDIT_PROFILE_FIELDS.mxHasInfonavitLoan,
    EDIT_PROFILE_FIELDS.mxHasFonacotLoan,
  ]);
  const educationFields = useInScopeFields([
    EDIT_PROFILE_FIELDS.brEducationalQualification,
    EDIT_PROFILE_FIELDS.esEducationalQualification,
  ]);
  const cartaoDeCidadaoFields = useInScopeFields([
    EDIT_PROFILE_FIELDS.ptCcIssDate,
    EDIT_PROFILE_FIELDS.ptCcExpDate,
  ]);
  const familyFields = useInScopeFields([
    EDIT_PROFILE_FIELDS.esChildrensBirthDates,
  ]);
  const countrySpecificFields = [
    ...identificationFields,
    ...contactFields,
    ...addressFields,
    ...jobFields,
    ...contractDetailsFields,
    ...educationFields,
    ...cartaoDeCidadaoFields,
    ...familyFields,
  ];

  const workCountry = profile.employment?.workCountry ?? undefined;

  if (!workCountry) {
    return <div>Something went wrong</div>;
  }

  function toggleCollapsibleSection(sectionName: keyof typeof open) {
    return () =>
      setOpen({
        ...open,
        [sectionName]: !open[sectionName],
      });
  }

  function navigateToProfile(queryParams?: Parameters<typeof encodeParams>[0]) {
    const path =
      linkToProfile + (queryParams ? `?${encodeParams(queryParams)}` : "");
    // force cache to refresh user nav with updated information if name was changed
    if (!isAdmin) {
      client.refetchQueries({ include: ["GetUserMenu"] });
    }
    navigate(path);
  }

  function handleCancelClick() {
    setIsModalOpen(true); // Open the modal
  }

  function handleModalClose() {
    setIsModalOpen(false); // Close the modal
  }

  async function onFormSubmit(values: EditableProfileInfoFormType) {
    clearError();

    const informationTypeGroupedFields = groupBy(
      countrySpecificFields,
      ({ informationType }) => informationType ?? "personalInfo"
    );

    const dynamicValues = mapValues(informationTypeGroupedFields, (fields) =>
      Object.fromEntries(
        fields.map(({ name }) => [
          name,
          (values as Record<string, unknown>)[name],
        ])
      )
    ) as Record<
      NonNullable<FieldDataType["informationType"]> | "personalInfo",
      Record<string, unknown>
    >;

    try {
      await onSubmit({
        personalInfo: {
          firstName: values.firstName,
          middleName: values.middleName,
          lastName: values.lastName,
          preferredFirstName: values.preferredFirstName,
          pronouns: values.pronouns,
          dateOfBirth: moment(values.dateOfBirth).format("YYYY-MM-DD"),
          emergencyContact: {
            firstName: values.emergencyContactFirstName,
            lastName: values.emergencyContactLastName,
            relationship: values.emergencyContactRelationship,
            phoneNumber: values.emergencyContactPhoneNumber.value,
          },
          email: values.homeEmail,
          ...dynamicValues.personalInfo,
        },
        contactDetails: {
          homePhoneNumber: values.phoneNumbers.find(
            matches({ type: PHONE_TYPES.home })
          ),
          workPhoneNumber: values.phoneNumbers.find(
            matches({ type: PHONE_TYPES.work })
          ),
          mobilePhoneNumber: values.phoneNumbers.find(
            matches({ type: PHONE_TYPES.mobile })
          ),
          ...mapValues(dynamicValues.address, (address) =>
            omit(address as Record<string, string>, ["__typename", "type"])
          ),
        },
        ...(isAdmin && {
          jobInformation: {
            managerUuid: values.managerUuid,
            departmentUuid: values.departmentUuid,
            workId: values.workId,
          },
        }),
        employment: {
          ...(isAdmin && { workEmail: values.workEmail }),
        },
      });

      const preferredName = values.preferredFirstName || undefined;
      const memberName = preferredName ?? values.firstName ?? "the member";

      const updatedAlertText = `The changes you made to ${
        isAdmin ? `${memberName}’s` : "your"
      } profile have been saved.`;

      navigateToProfile({ updated: updatedAlertText });
    } catch {
      onError(t("An unknown error occurred. Please try again later."));
    }
  }

  const formSchema = generateFormValidations(countrySpecificFields, t);

  return (
    <>
      {isModalOpen && (
        <CancelEditModal
          isOpen={isModalOpen}
          onClose={handleModalClose}
          navigateBack={() => navigate(linkToProfile)}
        />
      )}
      <Formik
        initialValues={getInitialValues(countrySpecificFields, profile)}
        onSubmit={onFormSubmit}
        validationSchema={formSchema}
      >
        {(formikProps) => {
          return (
            <Form onSubmit={formikProps.handleSubmit}>
              <div className={styles.collapsesContainer}>
                <EditIdentificationInformation
                  open={open.general}
                  toggleOpen={toggleCollapsibleSection("general")}
                  {...formikProps}
                />

                {!!familyFields.length && (
                  <CollapsibleCard
                    open={open.family}
                    title={t("Family")}
                    onClick={toggleCollapsibleSection("family")}
                  >
                    {familyFields.map((field) => (
                      <AutoFormField key={field.name} {...field} />
                    ))}
                  </CollapsibleCard>
                )}

                {!!educationFields.length && (
                  <CollapsibleCard
                    open={open.education}
                    title={t("Education")}
                    onClick={toggleCollapsibleSection("education")}
                  >
                    {educationFields.map((field) => (
                      <AutoFormField key={field.name} {...field} />
                    ))}
                  </CollapsibleCard>
                )}

                <EditAddressInformation
                  open={open.address}
                  toggleOpen={toggleCollapsibleSection("address")}
                  {...formikProps}
                />

                <EditContactInformation
                  open={open.contact}
                  toggleOpen={toggleCollapsibleSection("contact")}
                />

                {!!jobFields.length && (
                  <CollapsibleCard
                    open={open.job}
                    title={t("Job Information")}
                    onClick={toggleCollapsibleSection("job")}
                  >
                    {jobFields.map((field) => (
                      <AutoFormField key={field.name} {...field} />
                    ))}
                  </CollapsibleCard>
                )}

                {!!contractDetailsFields.length && (
                  <CollapsibleCard
                    open={open.contract}
                    title={t("Contract Details")}
                    onClick={toggleCollapsibleSection("contract")}
                  >
                    {contractDetailsFields.map((field) => (
                      <AutoFormField key={field.name} {...field} />
                    ))}
                  </CollapsibleCard>
                )}

                {!!cartaoDeCidadaoFields.length && (
                  <CollapsibleCard
                    open={open.cartao}
                    title={t("Cartão de Cidadão")}
                    onClick={toggleCollapsibleSection("cartao")}
                  >
                    {cartaoDeCidadaoFields.map((field) => (
                      <AutoFormField key={field.name} {...field} />
                    ))}
                  </CollapsibleCard>
                )}

                <ActionFooter
                  actions={
                    <>
                      <Button
                        onClick={handleCancelClick}
                        disabled={formikProps.isSubmitting}
                        color="brand"
                        variant="ghost"
                      >
                        {t("Cancel")}
                      </Button>
                      <Button
                        disabled={formikProps.isSubmitting}
                        loading={formikProps.isSubmitting}
                        type="submit"
                      >
                        {t("Update")}
                      </Button>
                    </>
                  }
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default EditableProfile;

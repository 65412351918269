import { FieldDataType, FieldTypes } from "./types";

const deepMergeValidations = (
  obj1: Record<string, unknown>,
  obj2: Record<string, unknown>
) => {
  for (const key in obj2) {
    if (!obj2.hasOwnProperty(key)) continue;
    if (key === "__proto__" || key === "constructor") continue;
    if (obj2[key] instanceof Object && obj1[key] instanceof Object) {
      obj1[key] = deepMergeValidations(
        obj1[key] as Record<string, unknown>,
        obj2[key] as Record<string, unknown>
      );
    } else {
      obj1[key] = obj2[key];
    }
  }
  return obj1;
};

export const addValidation = (
  formField: FieldDataType,
  validation: Record<string, unknown>
) => {
  const oldValidations = formField.validations;
  const newField = JSON.parse(JSON.stringify(formField));
  newField.validations = deepMergeValidations(validation, oldValidations || {});
  return newField;
};

export const cleanFieldValues = (
  formFields: Record<string, FieldDataType>,
  values: Record<string, string>
) => {
  for (const [key, value] of Object.entries(values)) {
    values[key] = formFields[key]?.compact?.(value) || value;
  }
  return values;
};

export const addOptions = (
  formField: FieldDataType,
  options: { value: string | number; description: string }[]
) => {
  if (
    formField.type !== FieldTypes.select &&
    formField.type !== FieldTypes.group
  ) {
    throw new Error("Field must be of type select or radio group");
  }
  const oldOptions = formField.options;
  const newField = JSON.parse(JSON.stringify(formField));
  newField.options = oldOptions.concat(options);
  newField.value = newField.options?.[0]?.value;
  return newField;
};

import { Select, SelectOption } from "@justworkshr/milo-form";
import IntlPhoneNumberInput from "pages/employer-of-record/IntlPhoneNumberInput";
import { PHONE_TYPES } from "pages/employer-of-record/onboarding/contact-info/PhoneNumbersFragment";
import difference from "lodash/difference";
import uniq from "lodash/uniq";
import styles from "./TypedInternationalPhoneNumberInput.module.css";
import { getIn, useFormikContext } from "formik";
import { useInScopeMemberData } from "pages/employer-of-record/employee-profile/components/EditableProfile/components/CountrySpecific/useInScopeMemberData";
import { CountryCode } from "libphonenumber-js";
import clsx from "lib/styling-utils/clsx";

type Props = {
  name: string;
  value: { value: string; type: string };
  withoutPhoneNumberType?: boolean;
  onChange: (e: React.ChangeEvent) => void;
  onBlur: (e: React.FocusEvent) => void;
};

export default function TypedInternationalPhoneNumberInput(props: Props) {
  const { onChange, onBlur, value, name } = props;

  const { values } = useFormikContext();
  const inScopeMemberData = useInScopeMemberData();

  const phoneNumbers: { type: string; value: string }[] = getIn(
    values,
    "phoneNumbers"
  );

  const usedTypes = Array.isArray(phoneNumbers)
    ? (phoneNumbers
        .map((phoneNumber) => phoneNumber.type)
        .filter((value) => value) as string[])
    : [];
  const unusedPhoneTypes = difference(Object.values(PHONE_TYPES), usedTypes);

  return (
    <div data-testid="typed-phone-input">
      <IntlPhoneNumberInput
        className={clsx({
          [styles.phoneValueInput]: !props.withoutPhoneNumberType,
        })}
        name={`${name}.value`}
        onChange={onChange}
        onBlur={onBlur}
        value={value.value}
        countryCode={
          inScopeMemberData?.eorEmployeeProfile.employment
            ?.workCountry as CountryCode
        }
      />
      {!props.withoutPhoneNumberType && (
        <div className={styles.phoneTypeSelect}>
          <Select
            name={`${name}.type`}
            onChange={onChange}
            onBlur={onBlur}
            value={value.type}
          >
            {uniq([value.type, ...unusedPhoneTypes]).map((type) => (
              <SelectOption value={type} key={type}>
                {type}
              </SelectOption>
            ))}
          </Select>
        </div>
      )}
    </div>
  );
}

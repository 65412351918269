import { CountryCode } from "pages/employer-of-record/onboarding/constants";
import * as CA_CONFIGS from "./countryConfigs/CA/constants";
import * as GB_CONFIGS from "./countryConfigs/GB/constants";
import * as MX_CONFIGS from "./countryConfigs/MX/constants";
import * as ES_CONFIGS from "./countryConfigs/ES/constants";
import * as NL_CONFIGS from "./countryConfigs/NL/constants";
import * as PT_CONFIGS from "./countryConfigs/PT/constants";
import * as CO_CONFIGS from "./countryConfigs/CO/constants";
import * as IE_CONFIGS from "./countryConfigs/IE/constants";
import * as IN_CONFIGS from "./countryConfigs/IN/constants";
import * as AU_CONFIGS from "./countryConfigs/AU/constants";
import * as BR_CONFIGS from "./countryConfigs/BR/constants";
import * as PH_CONFIGS from "./countryConfigs/PH/constants";
import { CountryConfigKeyType } from "./types";

const CONFIG_MAPS = {
  [CountryCode.CA]: CA_CONFIGS,
  [CountryCode.GB]: GB_CONFIGS,
  [CountryCode.MX]: MX_CONFIGS,
  [CountryCode.ES]: ES_CONFIGS,
  [CountryCode.NL]: NL_CONFIGS,
  [CountryCode.PT]: PT_CONFIGS,
  [CountryCode.CO]: CO_CONFIGS,
  [CountryCode.IE]: IE_CONFIGS,
  [CountryCode.IN]: IN_CONFIGS,
  [CountryCode.AU]: AU_CONFIGS,
  [CountryCode.BR]: BR_CONFIGS,
  [CountryCode.PH]: PH_CONFIGS,
};

export const loadCountryConfigFiles = (country: CountryConfigKeyType) => {
  const configs = CONFIG_MAPS[country];
  return configs;
};

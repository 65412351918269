import { Alert, Button, Card, PageHeader } from "@justworkshr/milo-core";
import {
  ActionFooter,
  Form,
  FormField,
  Select,
  SelectOption,
  TextInput,
} from "@justworkshr/milo-form";
import { Formik, type FormikProps } from "formik";
import omit from "lodash/omit";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import useOnboardingForm from "../../../hooks/useOnboardingForm";
import Layout from "../../../Layout";
import onboardingStyles from "../../../Onboarding.module.css";
import styles from "../../ProfileInfo.module.css";
import { NATIONALITIES } from "../../constants";
import BaseFormFragment, {
  initialValues as BASE_INITIAL_VALUES,
  schema as baseSchema,
} from "../base/BaseFormFragment";
import IdentifierNumberFormFragment, {
  initialValues as IDENTIFIER_NUMBER_INITIAL_VALUES,
  schema as identifierNumberSchema,
} from "../identifier-input/IdentifierNumberFormFragment";
import {
  brPersonalIdRegEx,
  MARITAL_STATUS_OPTIONS,
  WORK_PERMIT_OPTIONS,
  ETHNICITY_OPTIONS,
  STATE_OPTIONS,
  GENDER_OPTIONS,
} from "./constants.br";
import { compactTaxId } from "../../taxIdUtils";

const { inputField } = styles;

const state_key = "profile-info";

const ProfileInfoBR: FC = () => {
  const { t } = useTranslation();
  const {
    loading,
    memberData: { workCountry },
    form: { submitting, showErrorAlert, errorAlertMessage, onSubmit },
    memberOnboarding: {
      stepNumber,
      redirecting,
      profileInfo,
      idVerificationStatus,
    },
  } = useOnboardingForm(state_key);

  const countrySchema = Yup.object({
    nationality: Yup.string().required("This field is required."),
    brCityOfBirth: Yup.string().required("This field is required."),
    brGender: Yup.string()
      .oneOf(GENDER_OPTIONS.map((t) => t.value))
      .required(t("Gender is required")),
    brPersonalId: Yup.string()
      .required("This field is required.")
      .matches(brPersonalIdRegEx, t("Must be a valid Personal ID.")),
    brRgStateOfIssue: Yup.string()
      .oneOf(STATE_OPTIONS.map((t) => t.value))
      .required(t("State of issue is required")),
    brStateOfBirth: Yup.string()
      .oneOf(STATE_OPTIONS.map((t) => t.value))
      .required(t("State of birth is required")),
    brEthnicity: Yup.string()
      .oneOf(ETHNICITY_OPTIONS.map((t) => t.value))
      .required(t("Ethnicity is required")),
    brMaritalStatus: Yup.string()
      .oneOf(MARITAL_STATUS_OPTIONS.map((t) => t.value))
      .required(t("Marital status is required")),
    brEducationalQualification: Yup.string().required(
      "This field is required."
    ),
    brWorkPermitStatus: Yup.string()
      .oneOf(WORK_PERMIT_OPTIONS.map(({ value }) => value))
      .required("Work permit status is required"),
    brRgIssuingBody: Yup.string().required("This field is required."),
  });

  const validationSchema = countrySchema
    .concat(baseSchema(t))
    .concat(identifierNumberSchema(t, "BR"));

  type CountrySchema = Yup.InferType<typeof countrySchema>;
  type ValidationSchema = Yup.InferType<typeof validationSchema>;

  const INITIAL_VALUES: CountrySchema = {
    nationality: "",
    brCityOfBirth: "",
    brGender: "",
    brPersonalId: "",
    brRgStateOfIssue: "",
    brStateOfBirth: "",
    brEthnicity: "",
    brMaritalStatus: "",
    brEducationalQualification: "",
    brRgIssuingBody: "",
    brWorkPermitStatus: "",
  } as const;

  const PRIVATE_KEYS: readonly (keyof CountrySchema)[] = [
    "brRgIssuingBody",
    "brPersonalId",
  ];

  const DEFAULT_VALUES = {
    ...BASE_INITIAL_VALUES,
    ...IDENTIFIER_NUMBER_INITIAL_VALUES,
    ...INITIAL_VALUES,
  } as const;

  const getInitialValues = (): ValidationSchema => {
    if (!profileInfo?.profileInfo) return DEFAULT_VALUES;

    const sanitizedValues = omit(profileInfo?.profileInfo, PRIVATE_KEYS);

    return {
      ...DEFAULT_VALUES,
      ...sanitizedValues,
    };
  };

  const onFormSubmit = (values: ValidationSchema) => {
    const profileInfoIdentifierNumber = compactTaxId(
      workCountry,
      values.identifierNumber
    );

    onSubmit({
      profileInfo: {
        ...values,
        identifierNumber: profileInfoIdentifierNumber,
        confirmIdentifierNumber: undefined,
      },
    });
  };

  return (
    <Layout
      step={stepNumber}
      loading={loading || redirecting}
      idVerificationStatus={idVerificationStatus}
    >
      <>
        <Alert color="destructive" visible={showErrorAlert}>
          {errorAlertMessage ||
            t("An error occurred while submitting your profile information.")}
        </Alert>

        <PageHeader title="Complete your profile" />

        <Formik
          initialValues={getInitialValues()}
          onSubmit={onFormSubmit}
          validationSchema={validationSchema}
        >
          {({
            errors,
            handleBlur,
            handleSubmit,
            handleChange,
            touched,
            values,
          }: FormikProps<ValidationSchema>) => {
            const fieldErrorHandler = (name: keyof ValidationSchema) => {
              if (!errors || !touched) return;
              if (errors[name] && touched[name]) {
                return errors[name]?.toString();
              }
            };

            return (
              <Form onSubmit={handleSubmit}>
                <Card title={t("Personal information")}>
                  <BaseFormFragment />
                  <div className={inputField}>
                    <FormField
                      required
                      name="nationality"
                      label={t("Nationality")}
                      error={fieldErrorHandler("nationality")}
                    >
                      <Select
                        name="nationality"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={String(values.nationality)}
                        placeholder={
                          values.nationality.length
                            ? undefined
                            : t("Select Nationality")
                        }
                      >
                        {NATIONALITIES.map((option) => (
                          <SelectOption value={option.value} key={option.value}>
                            {option.description}
                          </SelectOption>
                        ))}
                      </Select>
                    </FormField>
                  </div>
                  <div className={inputField}>
                    <FormField
                      name="brGender"
                      required
                      label={t("Gender")}
                      error={touched?.brGender ? errors?.brGender : ""}
                      message={t(
                        "For the time being, we are limited to these two options. We recognize that life is more nuanced and are actively exploring ways to fully reflect everyone's experiences."
                      )}
                    >
                      <Select
                        name="brGender"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.brGender}
                        placeholder={t("Select Gender")}
                      >
                        {GENDER_OPTIONS.map((gender) => (
                          <SelectOption value={gender.value} key={gender.value}>
                            {gender.description}
                          </SelectOption>
                        ))}
                      </Select>
                    </FormField>
                  </div>
                  <div className={inputField}>
                    <FormField
                      name="brStateOfBirth"
                      required
                      label={t("State of birth ")}
                      error={
                        touched?.brStateOfBirth ? errors?.brStateOfBirth : ""
                      }
                    >
                      <Select
                        name="brStateOfBirth"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.brStateOfBirth}
                        placeholder={t("Select State of Birth")}
                      >
                        {STATE_OPTIONS.map((state) => (
                          <SelectOption value={state?.value} key={state?.value}>
                            {state?.description}
                          </SelectOption>
                        ))}
                      </Select>
                    </FormField>
                  </div>
                  <div className={inputField}>
                    <FormField
                      required
                      name="brCityOfBirth"
                      label={t("City of birth")}
                      error={fieldErrorHandler("brCityOfBirth")}
                    >
                      <TextInput
                        name="brCityOfBirth"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.brCityOfBirth}
                      />
                    </FormField>
                  </div>
                  <div className={inputField}>
                    <FormField
                      name="brEthnicity"
                      required
                      label={t("Ethnicity")}
                      error={touched?.brEthnicity ? errors?.brEthnicity : ""}
                    >
                      <Select
                        name="brEthnicity"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.brEthnicity}
                        placeholder={t("Select Ethnicity")}
                      >
                        {ETHNICITY_OPTIONS.map((ethnicity) => (
                          <SelectOption
                            value={ethnicity?.value}
                            key={ethnicity?.value}
                          >
                            {ethnicity?.description}
                          </SelectOption>
                        ))}
                      </Select>
                    </FormField>
                  </div>
                  <div className={inputField}>
                    <FormField
                      name="brWorkPermitStatus"
                      required
                      label={t("Work permit status")}
                      error={
                        touched?.brWorkPermitStatus
                          ? errors?.brWorkPermitStatus
                          : ""
                      }
                    >
                      <Select
                        name="brWorkPermitStatus"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.brWorkPermitStatus}
                        placeholder={t("Select Work Permit status")}
                      >
                        {WORK_PERMIT_OPTIONS.map(({ value, description }) => (
                          <SelectOption value={value} key={value}>
                            {description}
                          </SelectOption>
                        ))}
                      </Select>
                    </FormField>
                  </div>
                  <div className={inputField}>
                    <FormField
                      name="brMaritalStatus"
                      required
                      label={t("Marital status")}
                      error={
                        touched?.brMaritalStatus ? errors?.brMaritalStatus : ""
                      }
                    >
                      <Select
                        name="brMaritalStatus"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.brMaritalStatus}
                        placeholder={t("Select Marital status")}
                      >
                        {MARITAL_STATUS_OPTIONS.map(
                          ({ value, description }) => (
                            <SelectOption value={value} key={value}>
                              {description}
                            </SelectOption>
                          )
                        )}
                      </Select>
                    </FormField>
                  </div>
                  <div className={inputField}>
                    <FormField
                      required
                      name="brEducationalQualification"
                      label={t("Level of education")}
                      error={fieldErrorHandler("brEducationalQualification")}
                    >
                      <TextInput
                        name="brEducationalQualification"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={String(values.brEducationalQualification)}
                      />
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      required
                      name="brPersonalId"
                      label={t("ID Number (RG)")}
                      error={fieldErrorHandler("brPersonalId")}
                    >
                      <TextInput
                        name="brPersonalId"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.brPersonalId}
                      />
                    </FormField>
                  </div>
                  <div className={inputField}>
                    <FormField
                      name="brRgStateOfIssue"
                      required
                      label={t("RG state of issue")}
                      error={
                        touched?.brMaritalStatus ? errors?.brMaritalStatus : ""
                      }
                    >
                      <Select
                        name="brRgStateOfIssue"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.brRgStateOfIssue}
                        placeholder={t("Select state of issue")}
                      >
                        {STATE_OPTIONS.map((state) => (
                          <SelectOption value={state.value} key={state.value}>
                            {state.description}
                          </SelectOption>
                        ))}
                      </Select>
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      required
                      name="brRgIssuingBody"
                      label={t("RG issuing body")}
                      error={fieldErrorHandler("brRgIssuingBody")}
                    >
                      <TextInput
                        name="brRgIssuingBody"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={String(values.brRgIssuingBody)}
                      />
                    </FormField>
                  </div>

                  <IdentifierNumberFormFragment workCountry="BR" />
                </Card>

                <ActionFooter
                  className={onboardingStyles.footer}
                  actions={[
                    <Button
                      color="brand"
                      key="submit-btn"
                      loading={submitting}
                      type="submit"
                    >
                      {t("Save & continue")}
                    </Button>,
                  ]}
                />
              </Form>
            );
          }}
        </Formik>
      </>
    </Layout>
  );
};

export default ProfileInfoBR;

import { ReactElement } from "react";
import { useProfileContextData } from "pages/employer-of-record/employee-profile/contexts/ProfileInfoContext";
import Row from "../../../Row/Row";
import { Card } from "@justworkshr/milo-core";
import BadgeTitle from "../BadgeTitle";
import { useGetFeatureFlag } from "lib/launch-darkly";
import EditAction from "../../../EditAction/EditAction";

export default function FamilyCard(): ReactElement {
  const { profileInfo } = useProfileContextData();
  const { getFeatureFlag } = useGetFeatureFlag();

  const editable = !!getFeatureFlag(
    "release-edit-eor-ee-country-specific-fields"
  );

  const personalInfo = profileInfo?.eorEmployeeProfile.personalInfo;

  const esChildrensBirthDates = personalInfo?.esChildrensBirthDates;

  return (
    <>
      <Card
        title={
          (<BadgeTitle title="Family" badgeText="Spain" />) as unknown as string
        }
        actions={[<EditAction target="family" enabled={editable} />]}
      >
        <Row label="Children's birth dates" required={false}>
          {esChildrensBirthDates?.map((child, index) => (
            <div key={index}>
              Child {index + 1} birth date: {child}
            </div>
          ))}
        </Row>
      </Card>
    </>
  );
}

import { useProfileContextData } from "pages/employer-of-record/employee-profile/contexts/ProfileInfoContext";
import { useGetMemberAccountSettingsQuery } from "types/generated/operations";

export function useInScopeMemberData() {
  const { profileInfo } = useProfileContextData();
  const { data } = useGetMemberAccountSettingsQuery();

  if (!profileInfo?.eorEmployeeProfile || !data?.authenticatedMember) {
    return undefined;
  }

  const { eorEmployeeProfile } = profileInfo;
  const { authenticatedMember } = data;

  return { eorEmployeeProfile, authenticatedMember };
}

import { HEADERSTATUS } from "./constants";
import { GetEoREmployeeProfileQuery } from "types/generated/operations";

export const getStatusStyling = (
  status: string | undefined,
  styles: CSSModuleClasses
): string => {
  if (!status) {
    return "";
  }

  const statusStyle = styles[status];

  if (!statusStyle && status) {
    console.error(`Style not found for status: ${status}`);
  }

  switch (status) {
    case HEADERSTATUS.PENDING:
      return styles.pending;
    case HEADERSTATUS.SCHEDULED:
      return styles.scheduled;
    case HEADERSTATUS.TERMINATED:
      return styles.terminated;
    default:
      return "";
  }
};

export const getEmployeeStatus = (
  employeeInfo: GetEoREmployeeProfileQuery | undefined
): string => {
  if (!employeeInfo) {
    return "";
  }

  const { eorEmployeeProfile: profileInfo } = employeeInfo;
  const contractEndDate = profileInfo.contract?.endDate
    ? new Date(profileInfo.contract.endDate).getTime()
    : null;
  const nowDate = new Date().getTime();
  const startDate = profileInfo.contract?.startDate
    ? new Date(profileInfo.contract.startDate).getTime()
    : undefined;

  const isScheduled =
    profileInfo.offboarding?.requestedTerminationAt !== null ||
    (contractEndDate && contractEndDate > nowDate);
  const isBeforeStartDate = startDate && startDate > nowDate && !isScheduled;
  const isPending =
    (profileInfo.onboarding?.workflowState !== "complete" && !isScheduled) ||
    isBeforeStartDate;
  const isTerminated = contractEndDate && contractEndDate < nowDate;

  if (isPending) {
    return HEADERSTATUS.PENDING;
  } else if (isTerminated) {
    return HEADERSTATUS.TERMINATED;
  } else if (isScheduled) {
    return HEADERSTATUS.SCHEDULED;
  }

  return "";
};

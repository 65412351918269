import CollapsibleCard from "./CollapsibleCard";
import { useTranslation } from "react-i18next";
import { Divider } from "@justworkshr/milo-core";
import styles from "./EditIdentificationInformation.module.css";
import {
  EDIT_PROFILE_FIELDS,
  useInScopeFields,
} from "./CountrySpecific/edit-profile-autoform-config";
import AutoFormField from "pages/employer-of-record/components/AutoForm/AutoFormField";

const COUNTRY_SPECIFIC_FIELDS = [
  EDIT_PROFILE_FIELDS.ieMaritalStatus,
  EDIT_PROFILE_FIELDS.brCityOfBirth,
  EDIT_PROFILE_FIELDS.brStateOfBirth,
  EDIT_PROFILE_FIELDS.brMaritalStatus,
  EDIT_PROFILE_FIELDS.brEthnicity,
  EDIT_PROFILE_FIELDS.brWorkPermitStatus,
  EDIT_PROFILE_FIELDS.esMaritalStatus,
  EDIT_PROFILE_FIELDS.nationality,
];

export const IDENTIFICATION_FIELDS = [
  EDIT_PROFILE_FIELDS.firstName,
  EDIT_PROFILE_FIELDS.middleName,
  EDIT_PROFILE_FIELDS.lastName,
  EDIT_PROFILE_FIELDS.dateOfBirth,
  EDIT_PROFILE_FIELDS.preferredFirstName,
  EDIT_PROFILE_FIELDS.pronouns,
  ...COUNTRY_SPECIFIC_FIELDS,
];

type Props = {
  open: boolean;
  toggleOpen: () => void;
};

export default function EditIdentificationInformation(props: Props) {
  const { t } = useTranslation();
  const { open, toggleOpen } = props;

  const countrySpecificFields = useInScopeFields(COUNTRY_SPECIFIC_FIELDS);

  return (
    <CollapsibleCard
      open={open}
      title={t("Identification")}
      onClick={toggleOpen}
    >
      <AutoFormField {...EDIT_PROFILE_FIELDS.firstName} />
      <AutoFormField {...EDIT_PROFILE_FIELDS.middleName} />
      <AutoFormField {...EDIT_PROFILE_FIELDS.lastName} />

      <div className={styles.dateOfBirth}>
        <AutoFormField {...EDIT_PROFILE_FIELDS.dateOfBirth} />
      </div>

      <Divider />

      <AutoFormField {...EDIT_PROFILE_FIELDS.preferredFirstName} />
      <AutoFormField {...EDIT_PROFILE_FIELDS.pronouns} />

      {!!countrySpecificFields.length && (
        <>
          <Divider />
          {countrySpecificFields.map((field) => (
            <AutoFormField key={field.name} {...field} />
          ))}
        </>
      )}
    </CollapsibleCard>
  );
}

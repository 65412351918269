import { useProfileContextData } from "pages/employer-of-record/employee-profile/contexts/ProfileInfoContext";
import Row from "../../../Row/Row";
import { Card } from "@justworkshr/milo-core";
import BadgeTitle from "../BadgeTitle";
import EditAction from "../../../EditAction/EditAction";
import { useGetFeatureFlag } from "lib/launch-darkly";

export default function CartaoDeCidadaoCard() {
  const { getFeatureFlag } = useGetFeatureFlag();
  const isEditable = !!getFeatureFlag(
    "release-edit-eor-ee-country-specific-fields"
  );

  const { profileInfo } = useProfileContextData();

  const personalInfo = profileInfo?.eorEmployeeProfile.personalInfo;

  const ptCc = personalInfo?.ptCc;
  const ptCcIssDate = personalInfo?.ptCcIssDate;
  const ptCcExpDate = personalInfo?.ptCcExpDate;

  return (
    <>
      <Card
        title={
          (
            <BadgeTitle title="Cartão de Cidadão" badgeText="Portugal" />
          ) as unknown as string
        }
        actions={[<EditAction target="cartao" enabled={isEditable} />]}
      >
        <Row label="Number" value={ptCc} required={false} />
        <Row label="Issue date" value={ptCcIssDate} required={false} />
        <Row label="Expiration date" value={ptCcExpDate} required={false} />
      </Card>
    </>
  );
}

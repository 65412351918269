import { useGetCurrentMemberDepartmentsQuery } from "types/generated/operations";

export function useDepartmentOptions() {
  const { data: departmentsData } = useGetCurrentMemberDepartmentsQuery();

  const departments = departmentsData?.currentMember.company.departments;

  if (!departments) return [];

  return [
    { value: "", description: `No Department` },
    ...departments.map((department) => ({
      value: department.uuid,
      description: department.name,
    })),
  ];
}

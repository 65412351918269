import { useContext } from "react";
import { Formik, Form } from "formik";
import { Form8973Context } from "../../contexts";
import {
  WORKFLOW_STEPS,
  GENERIC_RADIO_OPTIONS,
  EIN_CHANGE_REASON_OPTIONS,
  LEGAL_ENTITY_NAME_CHANGE_REASON_OPTIONS,
  ERM_14_FORM_LINK,
} from "../../constants";
import { stepOneValidationSchema as getValidationSchema } from "../validationSchema";
import { filterFormValues, formatEinValue } from "../../utils";
import { Footer } from "../Footer/Footer";
import FormHeader from "../FormHeader/FormHeader";
import RenderInput from "../RenderInput/RenderInput";
import RenderAlert from "../RenderAlert/RenderAlert";
import { useProcessForm8973Answers } from "../../hooks/useProcessForm8973Answers";
import { FormikFormValuesType } from "../../types";
import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";
import { ErrorAlert } from "../ErrorAlert/ErrorAlert";
import { useState } from "react";
import styles from "./FormStepOne.module.css";

const { formStepContent, reviewInfo, formStepContentContainer, hiddenContent } =
  styles;

const getFieldsToSave = (
  values: FormikFormValuesType
): FormikFormValuesType => {
  const updatedValues = { ...filterFormValues(values) };

  // Clear EIN fields
  if (values.einValid === "true") {
    updatedValues.einChangeReason = "";
    updatedValues.correctedEin = "";
    updatedValues.einSupportingDocument = null;
  }

  if (values.einChangeReason === "einChanged") {
    updatedValues.correctedEin = "";
    updatedValues.einSupportingDocument = null;
  }

  // Clear Legal Entity Name fields
  if (values.legalEntityNameValid === "true") {
    updatedValues.legalEntityNameChangeReason = "";
    updatedValues.correctedLegalName = "";
    updatedValues.legalEntityNameSupportingDocument = null;
  }

  if (values.legalEntityNameChangeReason === "legalEntityNameChanged") {
    updatedValues.correctedLegalName = "";
    updatedValues.legalEntityNameSupportingDocument = null;
  }

  return updatedValues;
};

const FormStepOne: React.FC = () => {
  const { setStep, setPreviousStep, formValues, setFormValues, companyValues } =
    useContext(Form8973Context);
  const [error, setError] = useState<string | null>(null);
  const { processForm8973Answers, loading } = useProcessForm8973Answers();

  const handleFormSubmit = async (values: FormikFormValuesType) => {
    setError(null);
    const updatedValues = getFieldsToSave(values);

    if (
      updatedValues.legalEntityNameChangeReason === "legalEntityNameChanged" ||
      updatedValues.einChangeReason === "einChanged"
    ) {
      setFormValues(updatedValues);
      await processForm8973Answers({
        formValues: updatedValues,
        companyValues,
        onSuccess: () => {
          setStep(WORKFLOW_STEPS.CONFIRMATION_PENDING),
            setPreviousStep(WORKFLOW_STEPS.STEP_ONE);
        },
        onError: (message) => setError(message),
      });
    } else {
      setFormValues(updatedValues);
      setStep(WORKFLOW_STEPS.STEP_TWO);
      setPreviousStep(WORKFLOW_STEPS.STEP_ONE);
    }
  };

  return (
    <Formik
      initialValues={formValues}
      validationSchema={getValidationSchema}
      onSubmit={handleFormSubmit}
    >
      {({ values }) => (
        <Form>
          <FormHeader />
          {loading && <LoadingSpinner />}
          {error && <ErrorAlert error={error} />}
          <div className={loading ? hiddenContent : ""}>
            <div className={formStepContentContainer}>
              <div className={formStepContent}>
                <h2 className={reviewInfo}>
                  Let’s review the info we have on file for your company:
                </h2>
                <RenderInput
                  name="einValid"
                  label={`Is your company EIN ${formatEinValue(
                    companyValues.ein
                  )}?`}
                  type="radio"
                  options={GENERIC_RADIO_OPTIONS}
                  required
                />
                {values.einValid === "false" && (
                  <>
                    <RenderInput
                      name="einChangeReason"
                      label="Why do you need to make a change?"
                      type="select"
                      options={EIN_CHANGE_REASON_OPTIONS}
                      placeholder="Select..."
                      required
                    />
                    {values.einChangeReason === "einClericalError" && (
                      <>
                        <RenderInput
                          name="correctedEin"
                          label="Enter the correct EIN"
                          type="text"
                          placeholder="123456789"
                          required
                        />
                        <RenderInput
                          name="einSupportingDocument"
                          textAbove="Upload supporting documents"
                          type="file"
                          label="Upload one of the following documents: Form SS-4 Application for Employer Identification Number or any IRS document that shows the correct EIN."
                          textBelow="We can accept JPEG, PNG, or PDF up to 10 MB each."
                          required
                        />
                      </>
                    )}
                    {values.einChangeReason === "einChanged" && (
                      <RenderAlert
                        headline="Additional documentation needed"
                        message={
                          <>
                            To make these updates,{" "}
                            <a
                              href={ERM_14_FORM_LINK}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              download the ERM-14 form
                            </a>{" "}
                            and then contact your onboarding manager.
                          </>
                        }
                      />
                    )}
                  </>
                )}
                <RenderInput
                  name="legalEntityNameValid"
                  label={`Is your company's legal entity name ${companyValues.legalEntityName}?`}
                  type="radio"
                  options={GENERIC_RADIO_OPTIONS}
                  required
                />
                {values.legalEntityNameValid === "false" && (
                  <>
                    <RenderInput
                      name="legalEntityNameChangeReason"
                      label="Why do you need to make a change?"
                      type="select"
                      options={LEGAL_ENTITY_NAME_CHANGE_REASON_OPTIONS}
                      placeholder="Select..."
                      required
                    />
                    {values.legalEntityNameChangeReason ===
                      "legalEntityNameClericalError" && (
                      <>
                        <RenderInput
                          name="correctedLegalName"
                          label="Enter the correct legal entity name"
                          type="text"
                          required
                        />
                        <RenderInput
                          name="legalEntityNameSupportingDocument"
                          textAbove="Upload supporting documents"
                          label="Upload one of the following documents: an EIN assignment letter or any IRS document that shows the correct legal entity name."
                          textBelow="We can accept JPEG, PNG, or PDF up to 10 MB each."
                          type="file"
                          required
                        />
                      </>
                    )}
                    {values.legalEntityNameChangeReason ===
                      "legalEntityNameChanged" && (
                      <RenderAlert
                        headline="Contact your onboarding manager"
                        message={
                          <>
                            You can use the same{" "}
                            <a
                              href={ERM_14_FORM_LINK}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              ERM-14 form
                            </a>{" "}
                            to update your company EIN and legal entity name at
                            the same time.
                          </>
                        }
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <Footer />
        </Form>
      )}
    </Formik>
  );
};

export default FormStepOne;

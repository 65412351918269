import { useFormikContext } from "formik";
import { ReactElement, useContext } from "react";
import { AdditionalPayFormData, SetupStepData } from "../../types";
import { PAY_FORM_INITIAL_VALUES, employeePayTypes } from "../../constants";
import {
  assertUnreachable,
  getInitialEmployeePayload,
} from "pages/employer-of-record/payments/utils";
import { Card } from "@justworkshr/milo-core";
import { FormField, Select, SelectOption } from "@justworkshr/milo-form";
import { EmployeePayType } from "pages/employer-of-record/payments/constants";
import { Bonus } from "./BonusPay";
import { Comission } from "./Comission";
import styles from "../../Setup.module.css";
import { OffCycle } from "./OffCycle";
import { EmployeePayPayload } from "./types";
import CountrySelect from "../CountrySelect/CountrySelect";
import { Retroactive } from "./Retroactive";
import { AdditionalPayContext } from "pages/employer-of-record/payments/contexts/additionalPayContext";
import { CountryCode } from "pages/employer-of-record/onboarding/constants";

// This | string is a workaround for the unselected state
const formContent = (type: EmployeePayType | "") => {
  switch (type) {
    case EmployeePayType.BONUS:
      return <Bonus />;
    case EmployeePayType.COMISSION:
      return <Comission />;
    case EmployeePayType.OFF_CYCLE:
      return <OffCycle />;
    case EmployeePayType.RETROACTIVE:
      return <Retroactive />;
    case "":
    case undefined:
      // Default unselected case
      return null;
    default:
      assertUnreachable("Invalid pay type" + type);
  }
};

const payTypeDisplayName = (payType: EmployeePayType) => {
  switch (payType) {
    case EmployeePayType.OFF_CYCLE:
      return "Missed Payroll";
    default:
      return payType;
  }
};

const getAlertMessage = (payType: EmployeePayType) => {
  switch (payType) {
    case EmployeePayType.OFF_CYCLE:
      return (
        <p>
          Use this pay type to make up for hours that weren't paid during a
          previous payroll. To adjust the pay rate on wages that were previously
          paid, choose <strong>Retroactive pay</strong> instead.
        </p>
      );
    case EmployeePayType.RETROACTIVE:
      return (
        <>
          <p>
            This pay type should only be used for retroactive pay increases.
          </p>
          <p>
            Do not schedule this payment until after employee’s compensation has
            been updated in the system. Contact{" "}
            <strong>international-support@justworks.com</strong> to begin that
            process.
          </p>
        </>
      );
    default:
      return null;
  }
};

export const EmployeePay = (): ReactElement => {
  const { handleChange, errors, touched, values, setValues, handleBlur } =
    useFormikContext<AdditionalPayFormData<EmployeePayPayload>>();

  const additionalPayContext = useContext(AdditionalPayContext);
  const setUpData = values.setUp;

  const handlePayTypeChange = (selectedPayType: EmployeePayType) => {
    const newPayload = getInitialEmployeePayload(
      selectedPayType,
      additionalPayContext?.selectedCountry as CountryCode
    );

    if (newPayload) {
      const newInitialValues = {
        setUp: {
          workCountry: additionalPayContext?.selectedCountry as CountryCode,
          type: selectedPayType,
          payload: newPayload,
        },
        paymentDetails: PAY_FORM_INITIAL_VALUES.paymentDetails,
      };

      setValues(newInitialValues);
    }
  };

  // This as exists b/c we need to fix typing so setupData knows it is EmployeePayType Here
  const content = formContent(setUpData.type as EmployeePayType);
  const message = getAlertMessage(setUpData.type as EmployeePayType);

  const hasErrorForField = (
    fieldName: keyof SetupStepData<EmployeePayPayload>
  ) => {
    const hasError = errors.setUp && errors.setUp[fieldName];
    const isTouched = touched.setUp && touched.setUp[fieldName];
    return hasError && isTouched;
  };

  return (
    <>
      <div className={styles.card}>
        <Card title="Pay type">
          <div
            className={`${styles.inputField} ${
              hasErrorForField("workCountry") ? styles.error : ""
            }`}
          >
            <FormField
              label="Work country"
              required
              error={
                hasErrorForField("workCountry") ? errors.setUp?.workCountry : ""
              }
              message="You’ll choose employees to pay in the next step."
            >
              <CountrySelect />
            </FormField>
          </div>

          <div
            className={`${styles.inputField} ${
              hasErrorForField("type") ? styles.error : ""
            }`}
          >
            <FormField
              label="Pay type"
              required
              error={hasErrorForField("type") ? errors.setUp?.type : ""}
            >
              <Select
                name="setUp.payType"
                placeholder="Select..."
                value={setUpData.type}
                onBlur={handleBlur}
                onChange={(event) => {
                  handleChange(event);
                  handlePayTypeChange(event.target.value as EmployeePayType);
                }}
              >
                {employeePayTypes.map((payType) => (
                  <SelectOption key={payType} value={payType}>
                    {payTypeDisplayName(payType)}
                  </SelectOption>
                ))}
              </Select>
            </FormField>
          </div>
          {message && <div className={styles.typeInfoMessage}>{message}</div>}
        </Card>
      </div>

      <div className={styles.card}>{content}</div>
    </>
  );
};

import { useCallback, useContext, useEffect, useState } from "react";
import { ApolloError } from "@apollo/client";
import type { FormikValues } from "formik";
import { useNavigate } from "react-router-dom";
import {
  useBackEoROnboardingStateMutation,
  useSubmitEoROnboardingStateMutation,
} from "types/generated/operations";
import type { EoROnboardingStates } from "types";
import { EoROnboardingSteps, type StepperStatus } from "../constants";
import { EoRMemberContext } from "../../contexts/eorMemberContext";
import { OnboardingContext } from "../contexts/onboardingContext";
import { buildEoROnboardingPath } from "../routes";

const calculateStepNumber = (stateKey?: EoROnboardingStates) => {
  if (!stateKey) return -1;

  return EoROnboardingSteps.findIndex((step) => step.key === stateKey) + 1;
};

const useOnboardingForm = (stateKey: EoROnboardingStates) => {
  const navigate = useNavigate();

  const eorMemberContext = useContext(EoRMemberContext);
  const onboardingContext = useContext(OnboardingContext);

  const [navigatingBack, setNavigatingBack] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorAlertMessage, setErrorAlertMessage] = useState("");

  const [submitEoROnboardingState] = useSubmitEoROnboardingStateMutation();
  const [backEoROnboardingState] = useBackEoROnboardingStateMutation();

  useEffect(() => {
    if (!eorMemberContext?.eorMemberId) {
      navigate(buildEoROnboardingPath("/"));
    }
  }, [eorMemberContext?.eorMemberId, navigate]);

  const onSubmit = (values: FormikValues) => {
    setSubmitting(true);

    void submitEoROnboardingState({
      variables: {
        ...values,
        memberId: eorMemberContext?.eorMemberId || "",
        currentState: stateKey,
      },
      onCompleted(data) {
        if (data?.eorMemberOnboardingSubmitState?.state) {
          onboardingContext?.setCurrentStep(
            data.eorMemberOnboardingSubmitState.state as EoROnboardingStates
          );
        }

        if (stateKey === "id-verification") {
          onboardingContext?.refetchIdVerification();
        }

        onboardingContext?.dispatch({
          type: stateKey,
          payload: values,
        });
      },
      onError(error: ApolloError) {
        setSubmitting(false);
        setShowErrorAlert(true);
        setErrorAlertMessage(error.message.replace(", ", "\n"));
        window.scrollTo(0, 0);
      },
    });
  };

  const onBack = () => {
    setNavigatingBack(true);

    void backEoROnboardingState({
      variables: {
        memberId: eorMemberContext?.eorMemberId || "",
        currentState: stateKey,
      },
      onCompleted(data) {
        if (data?.eorMemberOnboardingBackState?.state) {
          onboardingContext?.setCurrentStep(
            data.eorMemberOnboardingBackState.state as EoROnboardingStates
          );
        }
      },
      onError(error: ApolloError) {
        setNavigatingBack(false);
        setShowErrorAlert(true);
        setErrorAlertMessage(error.message.replace(", ", "\n"));
      },
    });
  };

  const idVerificationStatusToStepperStatus = useCallback((): StepperStatus => {
    switch (onboardingContext?.idVerificationStatus) {
      case "pending":
        return "warning";
      case "approved":
        return "complete";
      case "declined":
        return "error";
      default:
        return "pending";
    }
  }, [onboardingContext?.idVerificationStatus]);

  const onboardingLoading =
    onboardingContext?.loading === undefined || onboardingContext?.loading;
  const eorMemberContextLoading =
    eorMemberContext?.loading === undefined || eorMemberContext?.loading;
  return {
    loading: onboardingLoading || eorMemberContextLoading,
    memberData: {
      memberId: eorMemberContext?.eorMemberId,
      workCountry: eorMemberContext?.workCountry,
    },
    form: {
      submitting,
      navigatingBack,
      showErrorAlert,
      errorAlertMessage,
      onSubmit,
      onBack,
    },
    memberOnboarding: {
      state: onboardingContext?.currentStep,
      stepNumber: calculateStepNumber(onboardingContext?.currentStep),
      redirecting: onboardingContext?.redirecting || false,
      profileInfo: onboardingContext?.profileInfo,
      contactInfo: onboardingContext?.contactInfo,
      idVerificationStatus: idVerificationStatusToStepperStatus(),
      paymentInfo: onboardingContext?.paymentInfo,
    },
  };
};

export default useOnboardingForm;

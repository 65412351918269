const MARITAL_STATUS_OPTIONS = [
  {
    value: "single",
    description: "Single",
  },
  {
    value: "married",
    description: "Married",
  },
  {
    value: "civilPartnership",
    description: "Civil Partnership",
  },
];

export { MARITAL_STATUS_OPTIONS };

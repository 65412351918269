import { ReactElement } from "react";
import styles from "./ApplyToAllButton.module.css";
import { SystemIcon } from "@justworkshr/milo-icons";
type Props = {
  applyToAll: (event: React.MouseEvent<HTMLButtonElement>) => void;
};
const ApplyToAllButton = ({ applyToAll }: Props): ReactElement => {
  const { button, icon, buttonContainer } = styles;
  return (
    <div className={buttonContainer}>
      <button className={button} type="button" onClick={applyToAll}>
        <SystemIcon className={icon} iconName="plus" color="brand" />
        Apply To All
      </button>
    </div>
  );
};

export default ApplyToAllButton;

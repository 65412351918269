import { Button } from "@justworkshr/milo-core";
import { Form8973Context } from "../../contexts";
import { useCallback, useContext } from "react";
import styles from "./FormBackButton.module.css";

const { backButton } = styles;

export const FormBackButton = () => {
  const { setStep, previousStep } = useContext(Form8973Context);

  const handleBack = useCallback(() => {
    setStep(previousStep);
  }, [previousStep, setStep]);

  return (
    <div className={backButton}>
      <Button variant="outlined" onClick={handleBack}>
        Back
      </Button>
    </div>
  );
};

import { IconButton } from "@justworkshr/milo-core";
import invoiceStyles from "../../CombinedInvoice.module.css";
import styles from "./CreditsSection.module.css";
import { FC, useContext } from "react";
import { toCurrencyFormat } from "../../../utils";
import { useTranslation } from "react-i18next";
import { CombinedInvoiceContext } from "../../contexts/CombinedInvoiceContext";
import { Category } from "../../types";
import summaryStyles from "../InvoiceSummary/components/SummaryCategory/SummaryCategory.module.css";

const { padlessGrid, contentSection, desktopVisible } = invoiceStyles;

const { totalLabel, total } = summaryStyles;

const {
  invoiceSummaryContainer,
  invoiceSummaryHeaderRow,
  invoiceSummaryHeader,
  invoiceSummaryTotalContainer,
  invoiceSummaryTotal,
  breakdownSectionButtonContainer,
} = styles;

interface InvoiceDetailedSummaryProps {
  credits: Category;
  baseCurrency: string;
  summaryIndex: number;
}

export const CreditsSection: FC<InvoiceDetailedSummaryProps> = ({
  credits,
  baseCurrency,
  summaryIndex,
}) => {
  const { t } = useTranslation();
  const { isBreakdownSectionExpanded, setIsBreakdownSectionExpanded } =
    useContext(CombinedInvoiceContext);
  const breakdownButtonHandler = () => {
    setIsBreakdownSectionExpanded((data) => ({
      ...data,
      [summaryIndex]: !data[summaryIndex],
    }));
  };

  return (
    <div className={invoiceSummaryContainer}>
      <div className={padlessGrid} id={`invoiceSummary_${summaryIndex}`}>
        <div className={invoiceSummaryHeaderRow}>
          <div className={breakdownSectionButtonContainer}>
            <IconButton
              size="md"
              color="neutral"
              type="button"
              variant="ghost"
              onClick={breakdownButtonHandler}
              title={
                isBreakdownSectionExpanded[summaryIndex] ? "Collapse" : "Expand"
              }
              iconName={
                isBreakdownSectionExpanded[summaryIndex]
                  ? "chevron-down"
                  : "chevron-right"
              }
            />
          </div>
          <span className={invoiceSummaryHeader}>{t("Credits")}</span>
        </div>
        <div className={invoiceSummaryTotalContainer}>
          <span className={invoiceSummaryHeader}>
            {t("Total")}
            <span className={invoiceSummaryTotal}>
              {toCurrencyFormat(Number(credits.total), baseCurrency, true)}
            </span>
          </span>
        </div>
      </div>

      {isBreakdownSectionExpanded[summaryIndex] &&
        credits.entries.map((entry) => (
          <div className={contentSection}>
            <div className={padlessGrid}>
              <div className={totalLabel}>{t(entry.description)}</div>
              <div className={`${total} ${desktopVisible}`} />
              <div className={total}>
                {toCurrencyFormat(Number(entry.amount), "USD", true)}
              </div>
            </div>{" "}
          </div>
        ))}
    </div>
  );
};

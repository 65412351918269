import React from "react";
import { DisplayIcon, DisplayIconProps } from "@justworkshr/milo-icons";
import { ConfirmationMessage } from "./ConfirmationMessageTypes";
import { ERM_14_FORM_LINK, WORKFLOW_STEPS } from "../../constants";

type AcceptedWorkflowSteps =
  | WORKFLOW_STEPS.CONFIRMATION_PENDING
  | WORKFLOW_STEPS.CONFIRMATION_RECEIVED
  | WORKFLOW_STEPS.CONFIRMATION_SUCCESS;

const Icon: React.FC<{
  iconName: DisplayIconProps["iconName"];
  title: DisplayIconProps["title"];
}> = ({ iconName, title }) => (
  <DisplayIcon color={"blue"} iconName={iconName} size="medium" title={title} />
);

export const ConfirmationMessages: Record<
  AcceptedWorkflowSteps,
  ConfirmationMessage
> = {
  [WORKFLOW_STEPS.CONFIRMATION_SUCCESS]: {
    title: "Nice work!",
    description: () =>
      "Our team will review the documents and reach out if we need any more info.",
    buttonLabel: "Done",
    icon: <Icon iconName="celebratory-horn" title="Celebration Icon" />,
  },
  [WORKFLOW_STEPS.CONFIRMATION_RECEIVED]: {
    title: "We got it!",
    description: () =>
      "Our team will review the updated information and documentation. We'll reach out if we have any questions or need more info.",
    buttonLabel: "Done",
    icon: <Icon iconName="check-mark" title="Check Icon" />,
  },
  [WORKFLOW_STEPS.CONFIRMATION_PENDING]: {
    title: "Almost there",
    description: (deadline?: string) => (
      <span>
        Don't forget to{" "}
        <a href={ERM_14_FORM_LINK} target="_blank" rel="noopener noreferrer">
          download the ERM-14 form
        </a>{" "}
        and reach out to your onboarding manager by {deadline} to make those
        updates.
      </span>
    ),
    buttonLabel: "Got it",
    icon: <Icon iconName="clock" title="Clock Icon" />,
  },
};

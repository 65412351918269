import { CountryCode } from "pages/employer-of-record/onboarding/constants";
import { EmployeePayType } from "pages/employer-of-record/payments/constants";
import * as CA_BONUS_CONFIGS from "./BonusPay/countryConfigs/CA/constants";
import * as CA_COMMISSION_CONFIGS from "./Comission/countryConfigs/CA/constants";
import * as CA_RETROACTIVE_CONFIGS from "./Retroactive/countryConfigs/CA/constants";
import * as CA_OFFCYCLE_CONFIGS from "./OffCycle/countryConfigs/CA/constants";
import * as GB_BONUS_CONFIGS from "./BonusPay/countryConfigs/GB/constants";
import * as GB_COMMISSION_CONFIGS from "./Comission/countryConfigs/GB/constants";
import * as GB_OFFCYCLE_CONFIGS from "./OffCycle/countryConfigs/GB/constants";
import * as GB_RETROACTIVE_CONFIGS from "./Retroactive/countryConfigs/GB/constants";
import * as MX_BONUS_CONFIGS from "./BonusPay/countryConfigs/MX/constants";
import * as MX_COMMISSION_CONFIGS from "./Comission/countryConfigs/MX/constants";
import * as MX_RETROACTIVE_CONFIGS from "./Retroactive/countryConfigs/MX/constants";
import * as MX_OFFCYCLE_CONFIGS from "./OffCycle/countryConfigs/MX/constants";
import * as ES_BONUS_CONFIGS from "./BonusPay/countryConfigs/ES/constants";
import * as ES_COMMISSION_CONFIGS from "./Comission/countryConfigs/ES/constants";
import * as ES_RETROACTIVE_CONFIGS from "./Retroactive/countryConfigs/ES/constants";
import * as ES_OFFCYCLE_CONFIGS from "./OffCycle/countryConfigs/ES/constants";
import * as NL_BONUS_CONFIGS from "./BonusPay/countryConfigs/NL/constants";
import * as NL_COMMISSION_CONFIGS from "./Comission/countryConfigs/NL/constants";
import * as NL_OFFCYCLE_CONFIGS from "./OffCycle/countryConfigs/NL/constants";
import * as NL_RETROACTIVE_CONFIGS from "./Retroactive/countryConfigs/NL/constants";
import * as PT_BONUS_CONFIGS from "./BonusPay/countryConfigs/PT/constants";
import * as PT_COMMISSION_CONFIGS from "./Comission/countryConfigs/PT/constants";
import * as PT_OFFCYCLE_CONFIGS from "./OffCycle/countryConfigs/PT/constants";
import * as PT_RETROACTIVE_CONFIGS from "./Retroactive/countryConfigs/PT/constants";
import * as CO_BONUS_CONFIGS from "./BonusPay/countryConfigs/CO/constants";
import * as CO_COMMISSION_CONFIGS from "./Comission/countryConfigs/CO/constants";
import * as CO_OFFCYCLE_CONFIGS from "./OffCycle/countryConfigs/CO/constants";
import * as CO_RETROACTIVE_CONFIGS from "./Retroactive/countryConfigs/CO/constants";
import * as IE_BONUS_CONFIGS from "./BonusPay/countryConfigs/IE/constants";
import * as IE_COMMISSION_CONFIGS from "./Comission/countryConfigs/IE/constants";
import * as IE_RETROACTIVE_CONFIGS from "./Retroactive/countryConfigs/IE/constants";
import * as IE_OFFCYCLE_CONFIGS from "./OffCycle/countryConfigs/IE/constants";
import * as IN_BONUS_CONFIGS from "./BonusPay/countryConfigs/IN/constants";
import * as IN_COMMISSION_CONFIGS from "./Comission/countryConfigs/IN/constants";
import * as IN_RETROACTIVE_CONFIGS from "./Retroactive/countryConfigs/IN/constants";
import * as IN_OFFCYCLE_CONFIGS from "./OffCycle/countryConfigs/IN/constants";
import * as AU_BONUS_CONFIGS from "./BonusPay/countryConfigs/AU/constants";
import * as AU_COMMISSION_CONFIGS from "./Comission/countryConfigs/AU/constants";
import * as AU_RETROACTIVE_CONFIGS from "./Retroactive/countryConfigs/AU/constants";
import * as AU_OFFCYCLE_CONFIGS from "./OffCycle/countryConfigs/AU/constants";
import * as BR_BONUS_CONFIGS from "./BonusPay/countryConfigs/BR/constants";
import * as BR_COMMISSION_CONFIGS from "./Comission/countryConfigs/BR/constants";
import * as BR_RETROACTIVE_CONFIGS from "./Retroactive/countryConfigs/BR/constants";
import * as BR_OFFCYCLE_CONFIGS from "./OffCycle/countryConfigs/BR/constants";
import * as PH_BONUS_CONFIGS from "./BonusPay/countryConfigs/PH/constants";
import * as PH_COMMISSION_CONFIGS from "./Comission/countryConfigs/PH/constants";
import * as PH_RETROACTIVE_CONFIGS from "./Retroactive/countryConfigs/PH/constants";
import * as PH_OFFCYCLE_CONFIGS from "./OffCycle/countryConfigs/PH/constants";
import { CountryConfigKeyType } from "./types";

const CONFIG_MAPS = {
  [CountryCode.CA]: {
    [EmployeePayType.BONUS]: CA_BONUS_CONFIGS,
    [EmployeePayType.COMISSION]: CA_COMMISSION_CONFIGS,
    [EmployeePayType.OFF_CYCLE]: CA_OFFCYCLE_CONFIGS,
    [EmployeePayType.RETROACTIVE]: CA_RETROACTIVE_CONFIGS,
  },
  [CountryCode.GB]: {
    [EmployeePayType.BONUS]: GB_BONUS_CONFIGS,
    [EmployeePayType.COMISSION]: GB_COMMISSION_CONFIGS,
    [EmployeePayType.OFF_CYCLE]: GB_OFFCYCLE_CONFIGS,
    [EmployeePayType.RETROACTIVE]: GB_RETROACTIVE_CONFIGS,
  },
  [CountryCode.MX]: {
    [EmployeePayType.BONUS]: MX_BONUS_CONFIGS,
    [EmployeePayType.COMISSION]: MX_COMMISSION_CONFIGS,
    [EmployeePayType.OFF_CYCLE]: MX_OFFCYCLE_CONFIGS,
    [EmployeePayType.RETROACTIVE]: MX_RETROACTIVE_CONFIGS,
  },
  [CountryCode.ES]: {
    [EmployeePayType.BONUS]: ES_BONUS_CONFIGS,
    [EmployeePayType.COMISSION]: ES_COMMISSION_CONFIGS,
    [EmployeePayType.OFF_CYCLE]: ES_OFFCYCLE_CONFIGS,
    [EmployeePayType.RETROACTIVE]: ES_RETROACTIVE_CONFIGS,
  },
  [CountryCode.NL]: {
    [EmployeePayType.BONUS]: NL_BONUS_CONFIGS,
    [EmployeePayType.COMISSION]: NL_COMMISSION_CONFIGS,
    [EmployeePayType.OFF_CYCLE]: NL_OFFCYCLE_CONFIGS,
    [EmployeePayType.RETROACTIVE]: NL_RETROACTIVE_CONFIGS,
  },
  [CountryCode.PT]: {
    [EmployeePayType.BONUS]: PT_BONUS_CONFIGS,
    [EmployeePayType.COMISSION]: PT_COMMISSION_CONFIGS,
    [EmployeePayType.OFF_CYCLE]: PT_OFFCYCLE_CONFIGS,
    [EmployeePayType.RETROACTIVE]: PT_RETROACTIVE_CONFIGS,
  },
  [CountryCode.CO]: {
    [EmployeePayType.BONUS]: CO_BONUS_CONFIGS,
    [EmployeePayType.COMISSION]: CO_COMMISSION_CONFIGS,
    [EmployeePayType.OFF_CYCLE]: CO_OFFCYCLE_CONFIGS,
    [EmployeePayType.RETROACTIVE]: CO_RETROACTIVE_CONFIGS,
  },
  [CountryCode.IE]: {
    [EmployeePayType.BONUS]: IE_BONUS_CONFIGS,
    [EmployeePayType.COMISSION]: IE_COMMISSION_CONFIGS,
    [EmployeePayType.OFF_CYCLE]: IE_OFFCYCLE_CONFIGS,
    [EmployeePayType.RETROACTIVE]: IE_RETROACTIVE_CONFIGS,
  },
  [CountryCode.IN]: {
    [EmployeePayType.BONUS]: IN_BONUS_CONFIGS,
    [EmployeePayType.COMISSION]: IN_COMMISSION_CONFIGS,
    [EmployeePayType.OFF_CYCLE]: IN_OFFCYCLE_CONFIGS,
    [EmployeePayType.RETROACTIVE]: IN_RETROACTIVE_CONFIGS,
  },
  [CountryCode.AU]: {
    [EmployeePayType.BONUS]: AU_BONUS_CONFIGS,
    [EmployeePayType.COMISSION]: AU_COMMISSION_CONFIGS,
    [EmployeePayType.OFF_CYCLE]: AU_OFFCYCLE_CONFIGS,
    [EmployeePayType.RETROACTIVE]: AU_RETROACTIVE_CONFIGS,
  },
  [CountryCode.BR]: {
    [EmployeePayType.BONUS]: BR_BONUS_CONFIGS,
    [EmployeePayType.COMISSION]: BR_COMMISSION_CONFIGS,
    [EmployeePayType.OFF_CYCLE]: BR_OFFCYCLE_CONFIGS,
    [EmployeePayType.RETROACTIVE]: BR_RETROACTIVE_CONFIGS,
  },
  [CountryCode.PH]: {
    [EmployeePayType.BONUS]: PH_BONUS_CONFIGS,
    [EmployeePayType.COMISSION]: PH_COMMISSION_CONFIGS,
    [EmployeePayType.OFF_CYCLE]: PH_OFFCYCLE_CONFIGS,
    [EmployeePayType.RETROACTIVE]: PH_RETROACTIVE_CONFIGS,
  },
} as const;

export const loadCountryConfigFiles = (
  type: EmployeePayType,
  country: CountryConfigKeyType
) => {
  const configs = CONFIG_MAPS[country][type];
  return configs;
};

import { ReactElement } from "react";
import styles from "../../InternationalSolutionsPage.module.css";
import { Box, Button } from "@justworkshr/milo-core";
import { CF_COST_CALCULATOR } from "pages/international-solutions/constants";

const ContractorsPriceCard = (): ReactElement => {
  const { divWrapper, mdGap, lgHead, smFont } = styles;
  return (
    <Box
      backgroundColor="neutral"
      border={{
        borderColor: "neutralSubtle",
        borderRadius: "md",
        borderWidth: "md",
      }}
      padding="2xl"
    >
      <div className={`${divWrapper} ${mdGap}`}>
        <div className={`${divWrapper} ${mdGap}`}>
          <div className={lgHead}>Cost calculator </div>
          <div className={smFont}>
            See cumulative employment costs based on employee location to make
            better and informed hiring decisions.{" "}
          </div>
        </div>
        <Button
          color="brand"
          type="button"
          target="_blank"
          as="a"
          size="md"
          href={CF_COST_CALCULATOR}
        >
          Go to calculator
        </Button>
      </div>
    </Box>
  );
};

export default ContractorsPriceCard;

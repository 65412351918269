import RequestSeparationLayout from "../components/Layout";
import { Button, Card, Row, Stack } from "@justworkshr/milo-core";
import { useTranslation } from "react-i18next";
import { useRequestSeparationContext } from "../context/RequestSeparationContext";
import { ActionFooter } from "@justworkshr/milo-form";
import { capitalize } from "pages/expenses/utils";
import { useEorMemberOffboardingMutation } from "types/generated/operations";
import { scrollToTop } from "pages/employer-of-record/invite/utils";
import { ApolloError } from "@apollo/client";
import { isoDateString } from "pages/employer-of-record/employee-profile/utils";

const RequestSeparationReview = () => {
  const [eorMemberOffboarding, { loading }] = useEorMemberOffboardingMutation();
  const { t } = useTranslation();
  const {
    memberName,
    memberId,
    setCurrentStep,
    formValues: { desiredLastDay, separationType, separationReason },
    setAlert,
  } = useRequestSeparationContext();

  const editDetails = () => setCurrentStep(1);

  const submitRequestSeparation = async () => {
    setAlert(null);

    const handleOnCompleted = () => {
      setCurrentStep(3);
    };

    const handleOnError = (error: ApolloError) => {
      const firstGraphQLError = error?.graphQLErrors?.[0];

      const errorMessage =
        firstGraphQLError?.extensions?.code === "CONFLICT_ERROR"
          ? "An existing termination request has already been initiated."
          : "Something went wrong. Please try again or give us a call at (888) 534-1711.";

      setAlert({
        visible: true,
        message: t(errorMessage),
        type: "destructive",
      });
      scrollToTop();
    };

    await eorMemberOffboarding({
      variables: {
        memberId,
        endDate: isoDateString(desiredLastDay),
        separationType,
        separationReason,
      },
      onCompleted: handleOnCompleted,
      onError: handleOnError,
    });
  };
  return (
    <RequestSeparationLayout headerTitle={`Separation of ${memberName}`}>
      <Stack spacing="2xl">
        <Card
          title="Details"
          actions={[<a onClick={editDetails}>Edit details</a>]}
        >
          <Stack spacing="2xl">
            <Row spacing="2xl">
              <div>
                <h3>{t("Desired last day of work")}</h3>
                <span>{desiredLastDay}</span>
              </div>
              <div>
                <h3>{t("Separation type")}</h3>
                <span>{capitalize(separationType)}</span>
              </div>
            </Row>
            <Row>
              <div>
                <h3>{t("Separation reason")}</h3>
                <span>{separationReason}</span>
              </div>
            </Row>
          </Stack>
        </Card>
        <ActionFooter
          actions={[
            <Button
              color="brand"
              key="back-btn"
              variant="outlined"
              onClick={editDetails}
            >
              {t("Previous")}
            </Button>,
            <Button
              color="brand"
              key="submit-btn"
              type="button"
              onClick={submitRequestSeparation}
              disabled={loading}
            >
              {t("Submit")}
            </Button>,
          ]}
        />
      </Stack>
    </RequestSeparationLayout>
  );
};

export default RequestSeparationReview;

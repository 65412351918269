import { useGetCurrentMemberCompanyDirectoryMembersQuery } from "types/generated/operations";

function managerLabel({
  deskTitle,
  friendlyFullName,
}: {
  deskTitle?: string | null;
  friendlyFullName: string;
}) {
  return deskTitle ? `${friendlyFullName} - ${deskTitle}` : friendlyFullName;
}

export function useManagerOptions() {
  const { data: directoryData } =
    useGetCurrentMemberCompanyDirectoryMembersQuery();

  const directoryMembers =
    directoryData?.currentMember.company.directoryMembers;

  if (!directoryMembers) return [];

  return [
    { value: "", description: `No Manager` },
    ...directoryMembers.map((member) => ({
      value: member.uuid,
      description: managerLabel(member),
    })),
  ];
}

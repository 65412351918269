import {
  FieldDataType,
  FieldTypes,
  SelectFieldType,
} from "pages/employer-of-record/components/AutoForm";
import { EorEmployeeProfile } from "types/employer-of-record";
import { useInScopeMemberData } from "./useInScopeMemberData";
import * as IRELAND from "pages/employer-of-record/onboarding/profile-info/form/ie/constants.ie";
import * as BRAZIL from "pages/employer-of-record/onboarding/profile-info/form/br/constants.br";
import * as SPAIN from "pages/employer-of-record/onboarding/profile-info/form/es/constants.es";
import { useGetFeatureFlag } from "lib/launch-darkly";
import moment from "moment";
import { NATIONALITIES } from "pages/employer-of-record/onboarding/profile-info/constants";
import { useDepartmentOptions } from "../../hooks/useDepartmentOptions";
import { useManagerOptions } from "../../hooks/useManagerOptions";

export type InternationalAddress = NonNullable<
  NonNullable<EorEmployeeProfile["contactDetails"]>["homeAddress"]
>;

export type InternationalPhoneNumber = NonNullable<
  NonNullable<EorEmployeeProfile["contactDetails"]>["homePhoneNumber"]
>;

type MemberDataValue =
  | string
  | boolean
  | InternationalAddress
  | InternationalPhoneNumber;

export type MemberDataField = FieldDataType & {
  canWrite: (
    profile: EorEmployeeProfile,
    authenticatedMember: { uuid: string }
  ) => boolean;
  value: (
    profile: EorEmployeeProfile
  ) => MemberDataValue | Array<MemberDataValue>;
};

const REQUIRED = {
  required: {
    enabled: true,
    message: "This field is required",
  },
};

// Helper so it's easy to make the type of EDIT_PROFILE_FIELDS Record<const, MemberDataField[]> instead of Record<string, MemberDataField[]>
function field(definition: MemberDataField): MemberDataField {
  return definition;
}

function personalInfo<
  K extends keyof NonNullable<EorEmployeeProfile["personalInfo"]>
>(
  key: K,
  defaultValue: NonNullable<NonNullable<EorEmployeeProfile["personalInfo"]>[K]>
) {
  return (profile: EorEmployeeProfile) =>
    profile.personalInfo?.[key] ?? defaultValue;
}

const omit = <T extends object, K extends keyof T>(
  removeKey: K,
  value: T
): Omit<T, K> =>
  Object.fromEntries(
    Object.entries(value).filter(([key]) => key !== removeKey)
  ) as Omit<T, K>;

function yes() {
  return true;
}

function isNotNil<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

function stripTypename<T extends { __typename?: string }>(
  thing: T
): Omit<T, "__typename"> {
  return Object.fromEntries(
    Object.entries(thing).filter(([key]) => key !== "__typename")
  ) as Omit<T, "__typename">;
}

const isAdmin: MemberDataField["canWrite"] = (profile, authenticatedMember) =>
  authenticatedMember.uuid !== profile.personalInfo?.memberUuid;

export function initialValues(
  fields: MemberDataField[],
  profile: EorEmployeeProfile
): Record<string, ReturnType<MemberDataField["value"]>> {
  const values: Record<string, ReturnType<MemberDataField["value"]>> = {};

  for (const field of fields) {
    values[field.name] = field.value(profile);
  }

  return values;
}

export function useInScopeFields(fields: MemberDataField[]): MemberDataField[] {
  const departments = useDepartmentOptions();
  const managers = useManagerOptions();
  const memberDataScope = useInScopeMemberData();
  const { getFeatureFlag } = useGetFeatureFlag();

  const countrySpecificFieldsEnabled = getFeatureFlag(
    "release-edit-eor-ee-country-specific-fields"
  );

  if (!memberDataScope || !countrySpecificFieldsEnabled) {
    return [];
  }

  const { eorEmployeeProfile, authenticatedMember } = memberDataScope;

  const managerField = fields.find(
    ({ name }) => name === EDIT_PROFILE_FIELDS.managerUuid.name
  );
  if (managerField) {
    (managerField as SelectFieldType).options = managers;
  }

  const departmentField = fields.find(
    ({ name }) => name === EDIT_PROFILE_FIELDS.departmentUuid.name
  );
  if (departmentField) {
    (departmentField as SelectFieldType).options = departments;
  }

  return fields.filter((field) =>
    field.canWrite(eorEmployeeProfile, authenticatedMember)
  );
}

export const EDIT_PROFILE_FIELDS = {
  firstName: field({
    type: FieldTypes.text,
    name: "firstName",
    label: "First name",
    validations: REQUIRED,
    canWrite: yes,
    value: personalInfo("firstName", ""),
  }),
  middleName: field({
    type: FieldTypes.text,
    name: "middleName",
    label: "Middle name",
    canWrite: yes,
    value: personalInfo("middleName", ""),
  }),
  lastName: field({
    type: FieldTypes.text,
    name: "lastName",
    label: "Last name",
    validations: REQUIRED,
    canWrite: yes,
    value: personalInfo("lastName", ""),
  }),
  dateOfBirth: field({
    type: FieldTypes.date,
    name: "dateOfBirth",
    label: "Date of birth",
    validations: {
      ...REQUIRED,
      date: {
        max: {
          value: moment().subtract(18, "years").toDate(),
          message: "Must be at least 18 years old",
        },
        min: {
          value: moment("01/01/1921", "MM/DD/YYYY").toDate(),
          message: "Too old!",
        },
      },
    },
    canWrite: yes,
    value: personalInfo("dateOfBirth", ""),
  }),
  preferredFirstName: field({
    type: FieldTypes.text,
    name: "preferredFirstName",
    label: "Preferred name",
    message:
      "This is the name you'll see in your company directory and in emails from us.",
    canWrite: yes,
    value: personalInfo("preferredFirstName", ""),
  }),
  pronouns: field({
    type: FieldTypes.text,
    name: "pronouns",
    label: "Pronouns",
    message:
      "This will be displayed on your public profile page and in your company org chart.",
    placeholder: "e.g. He/him, She/her, They/them",
    canWrite: yes,
    value: personalInfo("pronouns", ""),
  }),
  homeAddress: field({
    type: FieldTypes.address,
    informationType: "address",
    name: "homeAddress",
    label: "Home address",
    canWrite: yes,
    value: (profile) => {
      const address = profile.contactDetails?.homeAddress;

      if (address) return omit("type", address);

      return {
        address1: "",
        adddress2: "",
        zoneCode: "",
        city: "",
        countryCode: profile.employment?.workCountry,
        postalCode: "",
      };
    },
  }),
  mailingAddress: field({
    type: FieldTypes.address,
    informationType: "address",
    name: "mailingAddress",
    label: "Mailing address",
    canWrite: yes,
    value: (profile) => {
      const address = profile.contactDetails?.mailingAddress;

      if (address) return omit("type", address);

      return {
        address1: "",
        adddress2: "",
        zoneCode: "",
        city: "",
        countryCode: profile.employment?.workCountry,
        postalCode: "",
      };
    },
  }),
  phoneNumbers: field({
    type: FieldTypes.phoneNumber,
    informationType: "contact",
    array: {
      limit: 3,
    },
    name: "phoneNumbers",
    label: "Phone number",
    canWrite: yes,
    value: (profile) =>
      [
        profile.contactDetails?.mobilePhoneNumber,
        profile.contactDetails?.homePhoneNumber,
        profile.contactDetails?.workPhoneNumber,
      ]
        .filter(isNotNil)
        .map(stripTypename),
  }),
  homeEmail: field({
    type: FieldTypes.text,
    informationType: "contact",
    name: "homeEmail",
    label: "Home email address",
    validations: {
      ...REQUIRED,
      text: {
        email: {
          enabled: true,
          message: "Must be a valid email address",
        },
      },
    },
    canWrite: yes,
    value: personalInfo("email", ""),
  }),
  workEmail: field({
    type: FieldTypes.text,
    informationType: "contact",
    name: "workEmail",
    label: "Work email address",
    validations: {
      ...REQUIRED,
      text: {
        email: {
          enabled: true,
          message: "Must be a valid email address",
        },
      },
    },
    canWrite: isAdmin,
    value: (profile) => profile.employment?.workEmail ?? "",
  }),
  emergencyContactFirstName: field({
    type: FieldTypes.text,
    informationType: "contact",
    name: "emergencyContactFirstName",
    label: "First name",
    validations: REQUIRED,
    canWrite: yes,
    value: (profile) => profile.personalInfo?.emergencyContact?.firstName ?? "",
  }),
  emergencyContactLastName: field({
    type: FieldTypes.text,
    informationType: "contact",
    name: "emergencyContactLastName",
    label: "Last name",
    validations: REQUIRED,
    canWrite: yes,
    value: (profile) => profile.personalInfo?.emergencyContact?.lastName ?? "",
  }),
  emergencyContactRelationship: field({
    type: FieldTypes.text,
    informationType: "contact",
    name: "emergencyContactRelationship",
    label: "Relationship",
    validations: REQUIRED,
    canWrite: yes,
    value: (profile) =>
      profile.personalInfo?.emergencyContact?.relationship ?? "",
  }),
  emergencyContactPhoneNumber: field({
    type: FieldTypes.phoneNumber,
    withoutPhoneNumberType: true,
    informationType: "contact",
    name: "emergencyContactPhoneNumber",
    label: "Phone Number",
    validations: REQUIRED,
    canWrite: yes,
    value: (profile) =>
      profile.personalInfo?.emergencyContact?.phoneNumber ?? {
        type: "",
        value: "",
      },
  }),
  workId: field({
    type: FieldTypes.text,
    informationType: "job",
    name: "workId",
    label: "Work ID",
    validations: REQUIRED,
    canWrite: isAdmin,
    value: (profile) => profile.jobInformation?.workId ?? "",
  }),
  departmentUuid: field({
    type: FieldTypes.select,
    informationType: "job",
    name: "departmentUuid",
    label: "Department",
    placeholder: "Select Department",
    // These get monkey-patched at render-time with dynamic values from the backend
    options: [],
    canWrite: isAdmin,
    value: (profile) => profile.role?.departmentUuid ?? "",
  }),
  managerUuid: field({
    type: FieldTypes.select,
    informationType: "job",
    name: "managerUuid",
    label: "Manager",
    placeholder: "Select Manager",
    // These get monkey-patched at render-time with dynamic values from the backend
    options: [],
    canWrite: isAdmin,
    value: (profile) => profile.jobInformation?.managerInfo?.uuid ?? "",
  }),
  ieMaritalStatus: field({
    type: FieldTypes.select,
    name: "ieMaritalStatus",
    label: "Select marital status...",
    options: IRELAND.MARITAL_STATUS_OPTIONS,
    canWrite: (profile) => profile.employment?.workCountry === "IE",
    value: personalInfo("ieMaritalStatus", ""),
    validations: REQUIRED,
  }),
  mxHasInfonavitLoan: field({
    type: FieldTypes.boolean,
    name: "mxHasInfonavitLoan",
    label: "Do you have an Infonavit loan?",
    validations: REQUIRED,
    canWrite: (profile) => profile.employment?.workCountry === "MX",
    value: personalInfo("mxHasInfonavitLoan", false),
  }),
  mxHasFonacotLoan: field({
    type: FieldTypes.boolean,
    name: "mxHasFonacotLoan",
    label: "Do you have an Fonacot loan?",
    validations: REQUIRED,
    canWrite: (profile) => profile.employment?.workCountry === "MX",
    value: personalInfo("mxHasFonacotLoan", false),
  }),
  brMaritalStatus: field({
    type: FieldTypes.select,
    name: "brMaritalStatus",
    label: "Marital status",
    validations: REQUIRED,
    options: BRAZIL.MARITAL_STATUS_OPTIONS,
    canWrite: (profile) => profile.employment?.workCountry === "BR",
    value: personalInfo("brMaritalStatus", ""),
  }),
  brStateOfBirth: field({
    type: FieldTypes.select,
    name: "brStateOfBirth",
    label: "State of birth",
    validations: REQUIRED,
    options: BRAZIL.STATE_OPTIONS,
    canWrite: (profile) => profile.employment?.workCountry === "BR",
    value: personalInfo("brStateOfBirth", ""),
  }),
  brCityOfBirth: field({
    type: FieldTypes.text,
    name: "brCityOfBirth",
    label: "City of birth",
    validations: REQUIRED,
    canWrite: (profile) => profile.employment?.workCountry === "BR",
    value: personalInfo("brCityOfBirth", ""),
  }),
  brEthnicity: field({
    type: FieldTypes.select,
    name: "brEthnicity",
    label: "Ethnicity",
    options: BRAZIL.ETHNICITY_OPTIONS,
    validations: REQUIRED,
    canWrite: (profile) => profile.employment?.workCountry === "BR",
    value: personalInfo("brEthnicity", ""),
  }),
  brWorkPermitStatus: field({
    type: FieldTypes.select,
    name: "brWorkPermitStatus",
    label: "Work permit status",
    options: BRAZIL.WORK_PERMIT_OPTIONS,
    validations: REQUIRED,
    canWrite: (profile, authenticatedMember) =>
      profile.employment?.workCountry === "BR" &&
      authenticatedMember.uuid !== profile.personalInfo?.memberUuid,
    value: personalInfo("brWorkPermitStatus", ""),
  }),
  brEducationalQualification: field({
    type: FieldTypes.text,
    name: "brEducationalQualification",
    label: "Level of education",
    validations: REQUIRED,
    canWrite: (profile) => profile.employment?.workCountry === "BR",
    value: personalInfo("brEducationalQualification", ""),
  }),
  nationality: field({
    type: FieldTypes.select,
    name: "nationality",
    label: "Nationality",
    options: NATIONALITIES,
    validations: REQUIRED,
    canWrite: (profile) => profile.employment?.workCountry === "PT",
    value: personalInfo("nationality", ""),
  }),
  ptCcIssDate: field({
    type: FieldTypes.date,
    name: "ptCcIssDate",
    label: "Issue date",
    validations: REQUIRED,
    canWrite: (profile) => profile.employment?.workCountry === "PT",
    value: personalInfo("ptCcIssDate", ""),
  }),
  ptCcExpDate: field({
    type: FieldTypes.date,
    name: "ptCcExpDate",
    label: "Expiration date",
    validations: REQUIRED,
    canWrite: (profile) => profile.employment?.workCountry === "PT",
    value: personalInfo("ptCcExpDate", ""),
  }),
  esMaritalStatus: field({
    type: FieldTypes.select,
    name: "esMaritalStatus",
    label: "Marital status",
    validations: REQUIRED,
    options: SPAIN.esMaritalStatusOptions,
    canWrite: (profile) => profile.employment?.workCountry === "ES",
    value: personalInfo("esMaritalStatus", ""),
  }),
  esEducationalQualification: field({
    type: FieldTypes.select,
    name: "esEducationalQualification",
    label: "Level of education",
    validations: REQUIRED,
    options: SPAIN.esEducationalQualificationOptions,
    canWrite: (profile) => profile.employment?.workCountry === "ES",
    value: personalInfo("esEducationalQualification", ""),
  }),
  esChildrensBirthDates: field({
    array: {
      limit: 10,
    },
    type: FieldTypes.date,
    name: "esChildrensBirthDates",
    label: "Children's birth dates",
    canWrite: (profile) => profile.employment?.workCountry === "ES",
    value: personalInfo("esChildrensBirthDates", []),
  }),
};

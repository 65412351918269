import { useProfileContextData } from "pages/employer-of-record/employee-profile/contexts/ProfileInfoContext";
import Row from "../../../Row/Row";
import { useTranslation } from "react-i18next";
import { NATIONALITIES } from "pages/employer-of-record/onboarding/profile-info/constants";
import { getDescription } from "pages/employer-of-record/utils";
import {
  ETHNICITY_OPTIONS,
  WORK_PERMIT_OPTIONS,
  MARITAL_STATUS_OPTIONS,
  STATE_OPTIONS,
} from "pages/employer-of-record/onboarding/profile-info/form/br/constants.br";

export default function GeneralInformationBR() {
  const { t } = useTranslation();
  const { profileInfo } = useProfileContextData();

  const personalInfo = profileInfo?.eorEmployeeProfile.personalInfo;

  const stateOfBirth = getDescription(
    STATE_OPTIONS,
    personalInfo?.brStateOfBirth
  );
  const cityOfBirth = personalInfo?.brCityOfBirth;
  const nationality = getDescription(NATIONALITIES, personalInfo?.nationality);
  const ethnicity = getDescription(
    ETHNICITY_OPTIONS,
    personalInfo?.brEthnicity
  );
  const workPermitStatus = getDescription(
    WORK_PERMIT_OPTIONS,
    personalInfo?.brWorkPermitStatus
  );
  const maritalStatus = getDescription(
    MARITAL_STATUS_OPTIONS,
    personalInfo?.brMaritalStatus
  );
  const cpf = personalInfo?.identifierNumber;

  return (
    <>
      <Row label={t("State of birth")} value={stateOfBirth} />
      <Row label={t("City of birth")} value={cityOfBirth} />
      <Row label={t("Nationality")} value={nationality} />
      <Row label={t("Ethnicity")} value={ethnicity} />
      <Row label={t("Work permit status")} value={workPermitStatus} />
      <Row label={t("Marital status")} value={maritalStatus} />
      <Row label={t("Cadastro de Pessoas Físicas (CPF)")} value={cpf} />
    </>
  );
}
